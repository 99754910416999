import Swal from "sweetalert2";
import axios from "axios";

const axios_gopro = axios.create({
  baseURL: process.env.VUE_APP_BE_GOPRO,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

const axios_salesdashboard = axios.create({
  baseURL: process.env.VUE_APP_ERAFORCE_URL,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

const axios_upload = axios.create({
  baseURL: process.env.VUE_APP_BE_GOPRO,
  headers: {
    Accept: ["application/json", "multipart/form-data"],
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

export default {
  namespaced: true,
  state: {
    board: [],
    kanban: [],
    card: [],
    reporting: [],
    user: [],
    board_collabolator: [],
    dashboard: [],
    collab: [],
  },
  getters: {
    getBoardData: (state) => {
      return state.board;
    },
    getKanbanData: (state) => {
      return state.kanban;
    },
    getCardData: (state) => {
      return state.card;
    },
    getReporting: (state) => {
      return state.reporting;
    },
    getUser: (state) => {
      return state.user;
    },
    getDashboard: (state) => {
      return state.dashboard;
    },
    getCollab: (state) => {
      return state.collab;
    },
  },
  actions: {
    // Fetch
    fetchBoard({ commit }, body) {
      const params = [
        `id_board=${body.id_board}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`board?${params}`).then(
          (response) => {
            commit("SET_BOARD", response.data);
            resolve(response.data);
            console.log(`________ Test Board ${response.data}`);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    
    getBoards({ commit }, body) {
      const params = [
        `id_board=${body.id_board}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`board?${params}`).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    fetchSO({commit}, body) {
      const params = [
        `start_date=${body.start_date}`,
        `end_date=${body.end_date}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`sales_order/list?${params}`)
          .then((response) => {
            resolve(response.data);
          }, (error) => {
            reject(error);
          })
      })
    },

    fetchPO({commit}, body) {
      const params = [
        `start_date=${body.start_date}`,
        `end_date=${body.end_date}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`sales_order/listPo?${params}`)
          .then((response) => {
            resolve(response.data);
          }, (error) => {
            reject(error);
          })
      })
    },

    fetchPB({commit}, body) {
      const params = [
        `start_date=${body.start_date}`,
        `end_date=${body.end_date}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`sales_order/listPb?${params}`)
          .then((response) => {
            resolve(response.data);
          }, (error) => {
            reject(error);
          })
      })
    },

    fetchSI({commit}, body) {
      const params = [
        `start_date=${body.start_date}`,
        `end_date=${body.end_date}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`sales_order/listSi?${params}`)
          .then((response) => {
            resolve(response.data);
          }, (error) => {
            reject(error);
          })
      })
    },

    fetchJsonList({commit}, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.get(`sales_order/fileJson`)
          .then((response) => {
            resolve(response.data);
          }, (error) => {
            reject(error);
          })
      })
    },

    getMemberUserByBoard({commit}, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`board_collab/userCollab`, body) 
          .then((response) => {
            resolve(response.data);
          }, (error) => {
            reject(error);
          })
      })
    },

    fetchEmployeeTasksReporting({commit}, body) {
      const params = [
        `divisi=${body.divisi}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`getReporting/new?${params}`)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error);
        })
      })
    },

    exportEmployeeTasksReporting({commit}, body) {
      const params = [
        `start_date=${body.start_date}`,
        `end_date=${body.end_date}`,
        `divisi=${body.divisi}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`getReporting/exportTasksEmployee?${params}`)
        .then((response) => {
          resolve(response.data);
        }, (err) => {
          reject(err);
        })
      })
    },

    fetchBoardV2({ commit }, body) {
      const params = [
        `id_board=${body.id_board}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`board/v2?${params}`).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    fetchMemberCard({commit}, body) {
      const params = [
        `card_id=${body.card_id}`
      ].join("&");

      return new Promise((resolve, reject) => {
        axios_gopro.get(`memberCard?${params}`).then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error);
        })
      })
    },

    fetchUser({ commit }, body) {
      const params = [
        `id_user=${body.id_user}`,
        `id_departement=${body.id_departement}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");
      console.log("_____body", body);
      return new Promise((resolve, reject) => {
        axios_gopro.get(`users?${params}`).then(
          (response) => {
            commit("SET_USER", response.data);
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    fetchCollabolator({ commit }, body) {
      const params = [
        `id_board_collabolator=${body.id_board_collabolator}`,
        `board_id=${body.board_id}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");

      return new Promise((resolve, reject) => {
        axios_gopro.get(`board_collab?${params}`).then(
          (response) => {
            commit("SET_COLLAB", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchNotification({comit}, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.get('notifications')
          .then((response) => {
            resolve(response.data.data);
          }, (error) => {
            reject(error.response.data);
          })
      })
    },

    fetchKanban({ commit }, body) {
      const params = [
        `id_kanban=${body.id_kanban}`,
        `board_id=${body.board_id}`,
        `search=${body.search}`,
        `order=${body.order}`,
        `filter=${body.filter}`,
        `start_date=${body.start_date}`,
        `end_date=${body.end_date}`
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`kanban/v2?${params}`).then(
          (response) => {
            commit("SET_KANBAN", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchDetailKanban({commit}, body) {
      const params = [
        `id_kanban=${body.id_kanban}`,
        `board_id=${body.board_id}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`kanban/detailKanban?${params}`)
          .then((response) => {
            resolve(response.data.data);
          }, (error) => {
            reject(error.response.data);
          });
      })
    },

    fetchCard({ commit }, body) {
      const params = [
        `id_card=${body.id_card}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`card?${params}`).then(
          (response) => {
            commit("SET_CARD", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchReporting({ commit }, body) {
      const params = [
        `id_card=${body.id_card}`,
        `search=${body.search}`,
        `order=${body.order}`,
        `start_date_start=${body.start_date_start}`,
        `start_date_end=${body.start_date_end}`,
        `due_date_start=${body.due_date_start}`,
        `due_date_end=${body.due_date_end}`,
        `id_board=${body.id_board}`,
        `id_kanban=${body.id_kanban}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`getReporting?${params}`).then(
          (response) => {
            commit("SET_REPORTING", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchBoardCollabolator({ commit }, body) {
      const params = [
        `id_board_collabolator=${body.id_board_collabolator}`,
        `board_id=${body.board_id}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`board_collab/getCollab?${params}`).then(
          (response) => {
            commit("SET_BOARD_COLLABOLATOR", response.data.data);
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    fetchDetailBoard({commit}, body) {
      const params = [
        `id_board=${body.id_board}`
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`board/detail?${params}`)
          .then((response) => {
            resolve(response.data.data);
          }, (err) => {
            reject(err.response.data);
          })
      })
    },

    fetchSOList({commit}, body) {
      return new Promise((resolve, reject) => {
        axios_salesdashboard.get(`goopro/list/so`, body)
          .then((response) => {
            resolve(response.data);
          }, (err) => {
            reject(err.response.data);
          })
      })
    },

    exportExcelSO({commit}, body) {
      const params = [
        `start_date=${body.start_date}`,
        `end_date=${body.end_date}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`sales_order/exportSO?${params}`)
          .then((response) => {
            resolve(response.data);
          }, (err) => {
            reject(err.response.data);
          })
      })
    },

    exportExcelPO({commit}, body) {
      const params = [
        `start_date=${body.start_date}`,
        `end_date=${body.end_date}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`sales_order/exportPO?${params}`)
          .then((response) => {
            resolve(response.data);
          }, (err) => {
            reject(err.response.data);
          })
      })
    },

    exportExcelPB({commit}, body) {
      const params = [
        `start_date=${body.start_date}`,
        `end_date=${body.end_date}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`sales_order/exportPB?${params}`)
          .then((response) => {
            resolve(response.data);
          }, (err) => {
            reject(err.response.data);
          })
      })
    },

    exportExcelSI({commit}, body) {
      const params = [
        `start_date=${body.start_date}`,
        `end_date=${body.end_date}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`sales_order/exportSI?${params}`)
          .then((response) => {
            resolve(response.data);
          }, (err) => {
            reject(err.response.data);
          })
      })
    },

    fetchDashboard({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.get(`dashboard`).then(
          (response) => {
            commit("SET_DASHBOARD", response.data.result);
            resolve(response.data.result);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    fetchLocationMaps({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${body.latitude},${body.longitude}&key=AIzaSyCLEKzHA-9iVyvLskye9ysvPuU-A54SaJU`
          )
          .then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              reject(error.response);
            }
          );
      });
    },

    // END FETCH

    // CREATE
    doCreateBoard({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`board`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    doneSubmit({commit}, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`sales_order/syncFile`, body)
          .then((response) => {
            resolve(response.data);
          }, (error) => {
            reject(error.response);
          })
      }) 
    },

    doCreateKanban({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`kanban`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doCreateCard({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`card`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    doCreateComment({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`comment`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    doCreateAttachment({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_upload.post(`attachment`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    doCreateCardMember({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`memberCard`, body).then(
          (response) => {
            resolve(response.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    doDetailReporting({commit}, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`getReporting/detailMember`, body)
          .then((response) => {
            resolve(response.data);
          }, (err) => {
            reject(err.response.data);
          })
      })
    },

    doFetchReportingTasks({commit}, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`getReporting/new`, body)
          .then((response) => {
            resolve(response.data);
          }, (err) => {
            reject(err.response.data);
          })
      })
    },

    doCreateCollabolator({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`board_collab/create`, body).then(
          (response) => {
            resolve(response.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    // Export Excel
    doExportReport({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`getReporting/exportReporting`, body).then(
          (response) => {
            resolve(response.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    // Get Count Data
    doCountBoard({ commit }, body) {
      const params = [
        `id_board=${body.id_board}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_gopro.get(`board/count?${params}`).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    // End Get Count Data

    // UPDATE
    doUpdateBoard({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.put(`board`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doUpdatedKanban({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.put(`kanban`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    doUpdateCard({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.put(`card`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    doUpdateAttachment({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.put(`attachment`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    doChangeKanban({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`card/changeKanban`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    // DELETE
    doDeleteBoard({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`board/delete`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doDeleteKanban({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`kanban/delete`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    doDeleteCard({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`card/remove`, body).then(
          (response) => {
            resolve(response.data);
          },
          (err) => {
            reject(err.response);
          }
        );
      });
    },

    doDeleteAttachment({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`attachment/remove`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    doDeleteCollabolator({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`board_collab/remove`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (err) => {
            reject(err.response.data);
          }
        );
      });
    },

    doDeleteComment({commit}, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`comment/remove`, body)
          .then((response) => {
            resolve(response.data);
          }, (error) => {
            reject(error.response.data);
          })
      })
    },

    doDeleteMemberCard({commit}, body) {
      return new Promise((resolve, reject) => {
        axios_gopro.post(`memberCard/v2/remove`, body)
          .then((response) => {
            resolve(response.data);
          }, (error) => {
            reject(error.response.data);
          })
      })
    },

    // Chart
    fetchTasksChart({ commit }) {
      return new Promise((resolve, reject) => {
        axios_gopro.get(`card/chart/getTasks`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },
  },
  mutations: {
    SET_BOARD(state, data) {
      state.board = data;
    },
    SET_KANBAN(state, data) {
      state.kanban = data;
    },
    SET_CARD(state, data) {
      state.card = data;
    },
    SET_REPORTING(state, data) {
      state.reporting = data;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    SET_BOARD_COLLABOLATOR(state, data) {
      state.board_collabolator = data;
    },
    SET_DASHBOARD(state, data) {
      state.dashboard = data;
    },
    SET_COLLAB(state, data) {
      state.collab = data;
    },
  },
};
