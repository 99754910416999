<template>
  <div class="mx-4">
    <div class="card p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-bold">
          Board: <span class="badge badge-primary">{{ boards.nm_board }}</span>
          <i
            class="fas fa-info-circle"
            style="cursor: pointer"
            @click="handleDetailProject"
          ></i
        ></span>
        <div>
          <argon-button
            variant="gradient"
            color="primary"
            size="sm"
            @click="handleOpenAddProject()"
          >
            Add List
          </argon-button>
          <argon-button
            variant="gradient"
            color="info"
            size="sm"
            class="ms-1"
            @click="handleAddCollabolator()"
          >
            Add Collab
          </argon-button>
        </div>
      </div>
    </div>

    <div class="card p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <argon-button
            variant="gradient"
            color="primary"
            size="sm"
            @click="handleFilterByAll()"
          >
            All
          </argon-button>
          <argon-button
            variant="gradient"
            color="success"
            size="sm"
            class="ms-1"
            @click="handleFilterByDone()"
          >
            Done
          </argon-button>
          <argon-button
            variant="gradient"
            color="info"
            size="sm"
            class="ms-1"
            @click="handleFilterByProgress()"
          >
            Progress
          </argon-button>
          <!-- <div class="form-group">
            <label>Employee</label>
            <select class="form-control" @change="handleBoard($event)">
              <option value="">All Employee</option>
              <option>
                Employee
              </option>
            </select>
          </div> -->
        </div>
        <span class="text-bold">
          <el-date-picker
            type="daterange"
            v-model="params.date_range"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            size="large"
            style="margin-top: 2px; margin-right: 10px"
            value-format="YYYY-MM-DD"
            @change="handleChangeStartDate"
          />
          <argon-button
              variant="gradient"
              color="primary"
              size="md"
              @click="fetchLoaders"
          >
              Show Data
          </argon-button>
        </span>
      </div>
    </div>

    <div class="card p-3" v-if="is_loading">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div class="table-responsive" v-else>
      <!-- <el-scrollbar height="450px"> -->
        <div class="d-flex">
          <div
            class="col-md-3 m-2"
            v-for="item in kanban"
            :key="item.id_kanban"
          >
            <div class="card">
              <!-- <div class="ribbon">
                <span :style="{ background: `${item.color}` }">Board</span>
              </div> -->
              <div
                class="d-flex card-header pb-0 px-3 justify-content-between align-items-center"
              >
                <h6 class="mb-0">{{ item.title }} ({{ item.card.length }})</h6>
                <div>
                  <div class="justify-content-center">
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <!-- <i class="fas fa-caret-down"></i> -->
                        <i class="fas fa-ellipsis-v"></i>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            @click="handleOpenTask(item.id_kanban)"
                          >
                            New Task
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="handleOpenRenameProject(item)"
                          >
                            Rename List
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="handleOpenRemoveProject(item)"
                          >
                            Remove List
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                </div>
              </div>
              <div class="card-body pt-4 p-3">
                <el-scrollbar height="600px">
                  <ul class="list-group">
                    <draggable
                      :list="item.card"
                      item-key="id"
                      class="row"
                      group="people"
                      @change="checkMove(item.id_kanban, $event)"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <template #item="{ element }">
                        
                          <li
                            class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-200 border-radius-lg shadow-lg mt-1"
                            role="button"
                            @click="handleShowTasks(element.id_card)"
                          >
                            <div>
                              <div
                                class="d-flex justify-content-between align-items-center"
                              >
                                <h6 class="mb-1 text-sm">{{ element.title }}</h6>
                                <div class="justify-content-center">
                                  <el-dropdown>
                                    <span class="el-dropdown-link">
                                      <!-- <i class="fas fa-caret-down"></i> -->
                                      <i class="fas fa-ellipsis-v"></i>
                                    </span>
                                    <template #dropdown>
                                      <el-dropdown-menu>
                                        <el-dropdown-item
                                          @click="
                                            handleDeleteTasks(element.id_card)
                                          "
                                        >
                                          delete Tasks
                                        </el-dropdown-item>
                                      </el-dropdown-menu>
                                    </template>
                                  </el-dropdown>
                                </div>
                              </div>
                              <span class="text-xs">{{ element.description }}</span>

                              <div class="mt-3">
                                <div class="d-flex">
                                  <div>
                                    <span :class="'badge badge-sm ' + getColor(element.due_date, element.status)" v-if="element.due_date != null"
                                      ><i class="fas fa-clock"></i>
                                      {{ getDate(element.due_date) }}</span
                                    >
                                    <span class="badge badge-sm bg-warning" v-else
                                      ><i class="fas fa-clock"></i>
                                      -- </span
                                    >
                                  </div>
                                  <!-- <div class="ms-3" v-if="element.description">
                                    <i class="fas fa-align-justify"></i>
                                  </div> -->
                                  <div class="ms-3">
                                    <i class="fas fa-user-friends"></i>
                                    {{ element.memberCount }}
                                  </div>
                                  <div class="ms-3">
                                    <i class="fas fa-paperclip"></i>
                                    {{ element.attachCount }}
                                  </div>
                                  <div class="ms-3">
                                    <i class="fas fa-comments"></i>
                                    {{ element.commentCount }}
                                  </div>
                                </div>
                              </div>
                              <div class="mt-2" v-if="!element.user[0]">
                                <span class="text-xs">Created By: --</span>
                              </div>
                              <div class="mt-2" v-else>
                                <span class="text-xs">Created By: <strong>{{ element.user[0].fullname }}</strong></span>
                              </div>
                            </div>
                          </li>
                      </template>
                    </draggable>
                  </ul>
                </el-scrollbar>
              </div>
            </div>
          </div>
        </div>
      <!-- </el-scrollbar> -->
    </div>

    <!-- Rename Project -->
    <el-dialog v-model="rename_project" title="Rename Project" width="30%">
      <span>
        <el-input
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Project Name"
          :suffix-icon="EditPen"
          v-model="form.title"
        />
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            class="bg-gradient-success"
            @click="handleRenameKanban()"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Rename Project -->

    <!-- Add Project / Kanban -->
    <el-dialog
      v-model="add_project_dialog"
      title="Add New List"
      width="30%"
      :before-close="handleCloseAddProject()"
    >
      <span>
        <div class="m-2 w-100">
          <label>Title <span class="text-danger">*</span> </label>
          <input type="text" class="form-control" v-model="form.title" placeholder="Title"/>
        </div>
        <!-- <el-input
          v-model="form.title"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Title"
          :suffix-icon="EditPen"
        /> -->
        <div>
          <label>Color List <span class="text-danger">*</span> </label>
          <input type="color" class="m-2 w-100" v-model="form.color" />
        </div>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            class="bg-gradient-success"
            @click="handleCreateKanban()"
            :disabled="form.title == null || form.color == null"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      v-model="add_task_dialog"
      title="Add New Tasks"
      width="45%"
      :before-close="handleCloseAddTask()"
    >
      <span>
        <!-- <el-input
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Title"
          :suffix-icon="EditPen"
          v-model="formTasks.title"
        /> -->
        <div class="form-group m-2" v-if="getUserLoginData.user.id_user == 'UYUSB' || getUserLoginData.user.id_user == 'XR8PQ' || getUserLoginData.user.id_user == '26ITS' || getUserLoginData.user.id_user == 'WQT52' || getUserLoginData.user.id_user == '5ZIHQ' || getUserLoginData.user.id_user == '6302V' || getUserLoginData.user.id_user == '5UIHJ' || getUserLoginData.user.id_user == 'D2ST6' || getUserLoginData.user.id_user == 'HAMF4' || getUserLoginData.user.id_user == 'JWTER' || getUserLoginData.user.id_user == 'NFE0A' || getUserLoginData.user.id_user == 'Q8CJB' || getUserLoginData.user.id_user == 'T72EN'">
          <div class="input-group mb-3">
            <input
              type="search"
              id="usersearch"
              list="userList"
              class="form-control"
              placeholder="Search SO"
              @keydown.enter="addUser(selectedUser)"
              v-model="selectedUser"
              v-on:input="changingUser($event)"
            />
          </div>
          <datalist id="userList">
            <option v-for="u in listSOS" :key="u.so_id">{{ u.so_id }}</option>
          </datalist>
        </div>
        
        <div class="m-2 w-100">
          <label for="exampleFormControlInput1" class="form-label"
            >Title <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Title"
            v-model="formTasks.title" 
          />
        </div>
        <div class="m-2 w-100">
          <label for="exampleFormControlTextarea1" class="form-label"
            >Description <span class="text-danger">*</span></label
          >
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            placeholder="Description"
            rows="3"
            v-model="formTasks.description"
          ></textarea>
        </div>
        <div class="m-2 w-100">
          <label for="exampleFormControlInput1" class="form-label"
            >Due Date <span class="text-danger">*</span></label
          >
          <input
            type="datetime-local"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Due Date"
            v-model="formTasks.due_date"
          />
        </div>

        <div class="form-group">
          <label>Member Tasks <span class="text-danger">*</span></label>
          <div v-if="selected_user_app != null">
            <span class="text-center" v-if="is_loading_menu_data">
              <h6>Please wait...</h6>
            </span>
            <el-transfer
              v-else
              v-model="selected_menu_apps"
              :props="{
                key: 'id_user',
                label: 'fullname',
              }"
              :titles="['Source', 'Target']"
              :data="userCollab"
              style="width: 100%"
            />
          </div>
        </div>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            class="bg-gradient-success"
            @click="handleCreateTasks()"
            :disabled="formTasks.due_date == null || formTasks.due_date == '' "
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- Add Collabolator -->
    <el-dialog v-model="addBoardCollab" title="Add Collabolator" width="30%">
      <div class="form-group">
        <label>Division</label>
        <select class="form-control" @change="handleDivisi($event)">
          <option value="0">Choose Divisi</option>
          <option
            v-for="item in getDivisi"
            :key="item.id_departement"
            :value="item.id_departement"
          >
            {{ item.departement }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label>Assign To</label>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          :disabled="id_divisi == 0 || id_divisi == ''"
          @change="handleAssignTo($event)"
        >
          <option value="0">Choose User</option>
          <option
            v-for="usr in user"
            :key="usr.id_user"
            v-bind:value="usr.id_user"
          >
            {{ usr.fullname }}
          </option>
        </select>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="submitAddCollabolator()"
            :disabled="formCollab.user_id == 0 || !formCollab.user_id"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Add Collabolator -->

    <!-- Remove Project / Kanban -->
    <el-dialog
      v-model="remove_project"
      title=""
      width="30%"
      :before-close="handleCloseRemoveProject()"
    >
      <span>
        Are you sure want to delete project: <b>{{ form.title }}</b> ?
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="handleRemoveProject()"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Remove Project / Kanban -->

    <!-- Card Member -->
    <el-dialog v-model="card_member" title="Card Member" width="30%">
      <div class="form-group">
        <label>Board Member</label>
        <select class="form-control" @change="handleChooseTicket($event)">
          <option disabled="true" selected="true">
            Choose Member Ticket
          </option>
          <option v-for="items in boardCollab" :key="items" v-bind:value="items.user[0].id_user">{{ items.user[0].nm_user }} - {{ items.user[0].divisi }}</option>
        </select>
      </div>
      <!-- <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Members</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in boardCollab" :key="item.user_id">
              <th scope="row">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="item"
                    name="user_id"
                    id="flexCheckDefault"
                    v-model="checkMembers"
                  />
                </div>
              </th>
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <img
                      src="../../assets/img/team-2.jpg"
                      class="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item.user[0].nm_user }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      Division :
                      <b>
                        {{
                          item.user[0].divisi == null
                            ? "Kosong"
                            : item.user[0].divisi
                        }}
                      </b>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->

      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="handleMemberCards"
          >
            Add Member
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Card Member -->

    <!-- Attachment -->
    <el-dialog v-model="attachment" title="Attachment" width="30%">
      <div class="form-group">
        <label>New Attachment</label>
        <input
          type="file"
          class="form-control-file"
          v-on:change="onChangeFileUpload($event)"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="handleSubmitAttachment()"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Attachment -->

    <!-- Comment -->
    <el-dialog v-model="edit_comment" title="Comment" width="30%">
      <div class="form-group">
        <label>Update Comment</label>
        <textarea
          class="form-control"
          rows="2"
          v-model="update_comment.description"
          placeholder="Write a comments.."
        ></textarea>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="handleSubmitAttachment()"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Comment -->

    <!-- Label -->
    <el-dialog v-model="label_model" title="Label" width="30%">
      <!-- <div class="row">
        <div class="col-md-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Default checkbox
            </label>
          </div>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-3"></div>
      </div> -->
      <ul class="list-group">
        <li class="list-group-item">
          <div class="d-flex">
            <div>
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                  />
                </div>
              </div>
            </div>
            <div>Hello World Opo Iki</div>
            <div class="ml-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fas fa-edit"></i></div>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="d-flex">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
            </div>
            <div>Hello World Opo Iki</div>
            <div>Hello World Opo Iki</div>
          </div>
        </li>
      </ul>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="handleSubmitAttachment()"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Label -->

    <!-- Checklist -->
    <el-dialog v-model="checklist_model" title="Label" width="30%">
      <span>Checklist</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="handleSubmitAttachment()"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Checklist -->

    <!-- Dates -->
    <el-dialog v-model="dates_model" title="Dates" width="30%">
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Due Date</label
        >
        <input type="datetime-local" class="form-control" v-model="due_date" />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="handleUpdateDueDate(showTasks[0].id_card)"
            :disabled="!due_date"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Dates -->

    <!-- Detail Project/Board -->
    <el-dialog v-model="detailProject" title="Detail Board" :before="clearDetailCollab()" width="30%">
      <div class="table-responsive">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <th scope="row">Project Name</th>
              <td>{{ dataDetailProject.nm_board }}</td>
            </tr>
            <tr>
              <th scope="row">Background</th>
              <td><p class="text-white text-center" :style="{'background-color': dataDetailProject.background }">Colors</p></td>
            </tr>
            <tr>
              <th scope="row">Public</th>
              <td>{{ dataDetailProject.public == 1 ? "true" : "false" }}</td>
            </tr>
            <tr>
              <th scope="row">List</th>
              <td>
                <ul>
                  <li
                    v-for="cols in detailKanban"
                    :key="cols.id_kanban"
                  >
                    {{ cols.title }} <strong>({{ cols.card }})</strong>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope="row">Collabolator</th>
              <td>
                <ul>
                  <li
                    v-for="col in detailCollabolator"
                    :key="col.id_user"
                  >
                    {{ col.user[0].nm_user }}
                    <span
                      class="badge bg-danger"
                      style="cursor: pointer"
                      @click="handleDeleteCollabolator(col.board_collab.id_board_collabolator)"
                      >Delete</span
                    >
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope="row">Created by </th>
              <td><strong>{{ dataDetailProject.created_by }}</strong> - {{ formatDate(dataDetailProject.created_at)}}</td>
            </tr>
            <tr>
              <th scope="row">Action </th>
              <td><button class="btn btn-sm btn-danger" @click="removeBoard">Delete Board</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>
    <!-- End Detail Project/Board -->

    <!-- Edit Attachment -->
    <el-dialog v-model="edit_attachment" title="Update Attachment" width="30%">
      <div class="form-group">
        <label>Update Attachment</label>
        <input
          type="file"
          class="form-control-file"
          v-on:change="onChangeFileUpload($event)"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="HandleSubmitUpdateAttachment()"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Edit Attachment -->

    <!-- Detail Member Ticket -->
    <el-dialog v-model="detailMemberTasks" title="Detail Member" width="30%">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nama</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataDetailMemberTasks" :key="item.id">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.fullname }}</td>
              <td>
                <button class="btn btn-sm bg-grandient-danger" @click="handleDeleteMemberCard(item)">DELETE</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>
    <!-- End Detail Member Ticket -->

    <el-dialog v-model="qty_modal" title="Added Quantity" width="30%">
      <div class="m-2 w-100">
        <label for="exampleFormControlInput1" class="form-label"
          >Qty <span class="text-danger">*</span></label
        >
        <input
          type="number"
          class="form-control"
          id="exampleFormControlInput1"
          placeholder="Quantity"
          v-model="qtys"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="submitQty(1)"
            :disabled="qtys == null"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- Move -->
    <el-dialog v-model="move_ticket" title="Move List" width="30%">
      <div class="form-group">
        <label>Select List</label>
          <select class="form-control" @change="addIdList($event)">
            <option value="0">Choose List</option>
            <option v-for="item in detailKanban" :value="item.id_kanban" :fullname="item.title" :key="item">
              {{ item.title }}
            </option>
          </select>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="submitMove()"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Move -->

    <!-- Copy -->
    <el-dialog v-model="copy_ticket" title="Duplicate Tasks" width="30%">
      <span>
        <!-- <el-input
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Title"
          :suffix-icon="EditPen"
          v-model="formTasksDuplicate.title"
        /> -->
        <div class="m-2 w-100">
          <label for="exampleFormControlInput1" class="form-label"
            >Title <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Title"
            v-model="formTasksDuplicate.title"
          />
        </div>
        <div class="m-2 w-100">
          <label for="exampleFormControlTextarea1" class="form-label"
            >Description <span class="text-danger">*</span></label
          >
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            placeholder="Description"
            rows="3"
            v-model="formTasksDuplicate.description"
          ></textarea>
        </div>
        <div class="m-2 w-100">
          <label for="exampleFormControlInput1" class="form-label"
            >Due Date <span class="text-danger">*</span></label
          >
          <input
            type="datetime-local"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Due Date"
            v-model="formTasksDuplicate.due_date"
          />
        </div>
      </span>
      <div class="form-group">
        <label>Select Board <span class="text-danger">*</span></label>
          <select class="form-control" @change="getList($event)">
            <option value="0" selected disabled>Choose Board</option>
            <option v-for="item in boardDuplicate" :value="item.id_board" :key="item">
              {{ item.nm_board }}
            </option>
          </select>
      </div>
      <div class="form-group">
        <label>Select List <span class="text-danger">*</span></label>
          <select class="form-control" @change="addKanban($event)">
            <option value="0" selected disabled>Choose List</option>
            <option v-for="item in listCopy" :value="item.id_kanban" :key="item">
              {{ item.title }}
            </option>
          </select>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="submitDuplicate()"
            :disabled="formTasksDuplicate.title == null || formTasksDuplicate.title == '' || formTasksDuplicate.description == null || formTasksDuplicate.description == '' || formTasksDuplicate.due_date == null || formTasksDuplicate.due_date == '' || formTasksDuplicate.kanban_id == null"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Copy -->

    <!-- Detail Tasks -->
    <el-dialog v-model="detail_tasks" title="Detail Tasks" width="60%">
      <div class="row">
        <div class="col-md-9">
          <p>Created By <strong>{{ (showTasks[0].user.length > 0) ? showTasks[0].user[0].fullname : '--' }}</strong></p>
          <div class="card card-primary card-outline shadow p-3">
            <div class="mb-5">
              <div class="h5"
                ><i class="fas fa-chalkboard"></i> <span v-if="editTasks == false">{{ showTasks[0].title }}</span> <span v-else><input type="text" v-model="formEditTasks.title"/></span>
                <span v-if="editTasks == false">
                  <i class="fas fa-edit" style="cursor: pointer; margin-left: 10px" @click="changeEditTasks(showTasks[0].title)"></i>
                </span>
                <span v-else>
                  <i class="fas fa-check-circle" style="cursor: pointer; margin-left: 10px" @click="handleUpdateTasks"></i>
                  <i class="fas fa-times-circle" style="cursor: pointer; margin-left: 10px" @click="changeEditTasks(showTasks[0].title)"></i>
                </span>
              </div>
              <!-- <div class="row">
                <div class="col-md-8">
                  <i class="fas fa-chalkboard"></i>
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                </div>
              </div> -->
              <!-- <span class="h5">
                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
              </span> -->
              <br />
              <span>in List</span>

              <!-- Member Card -->
              <div class="m-3" v-if="showTasks[0].member_card.length > 0">
                <h6>Member Ticket</h6>
                <div class="d-flex">
                  <div class="avatar-group ms-2">
                    <a
                      href="#"
                      @click="detailMemberTicket(showTasks[0])"
                      class="avatar avatar-xs border-0 rounded-circle"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      v-for="thems in showTasks[0].member_card"
                      :key="thems.id"
                      style="pointer: cursor;"
                    >
                      <img
                        alt="Image placeholder"
                        src="../../assets/img/team-3.jpg"
                      />
                    </a>
                  </div>
                  <div>
                    Total Members: {{ showTasks[0].member_card.length }}
                  </div>
                </div>
              </div>
              <!-- End Member Card -->

              <!-- Due Date -->
              <div class="m-3">
                <div class="row">
                  <div class="col-md-6">
                    <h6>Start Date</h6>
                    <button type="button" class="btn btn-info btn-sm">
                      <i class="fas fa-clock"></i>
                      {{ getDate(showTasks[0].start_date) }}
                    </button>
                  </div>
                  <div class="col-md-6">
                    <h6>Due Date</h6>
                    <!-- <div class="form-check">
                      <input class="form-check-input" type="checkbox" :value="showTasks[0].status" v-if="showTasks[0].status == 0 || showTasks[0].status == '' || showTasks[0].status == null" @change="updateChecklist($event)" />
                      <input class="form-check-input" type="checkbox" :value="showTasks[0].status" v-if="showTasks[0].status == 1" checked @change="updateChecklist($event)" />
                      
                    </div> -->
                    <button v-if="showTasks[0].due_date" @click="showDates()" type="button" :class="getColorDueDate(showTasks[0].due_date, showTasks[0].status) + ' btn btn-sm'">
                        <i class="fas fa-clock"></i>
                        {{ getDateDue(showTasks[0].due_date) }}
                      </button>
                      <button v-else type="button" class="btn btn-sm btn-warning" @click="showDates()">
                        <i class="fas fa-clock"></i>
                        Update Due Date
                      </button>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="flexCheckChecked" :value="showTasks[0].status" v-if="showTasks[0].status == 0 || showTasks[0].status == '' || showTasks[0].status == null" @change="updateChecklist($event)" />
                      <input class="form-check-input" type="checkbox" id="flexCheckChecked" :value="showTasks[0].status" v-if="showTasks[0].status == 1" checked @change="updateChecklist($event)" />
                      <label class="form-check-label" for="flexCheckChecked">
                        Complete
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Description -->
            <div>
              <div class="mb-5">
                <span class="h5">
                  <i class="fas fa-th-list"></i> Description</span>
                  <i class="fas fa-edit ml-2" style="cursor: pointer; margin-left: 10px" @click="handleEditDescription(showTasks[0].description)"></i>
                <br />
                <div
                  class="m-4"
                  style="cursor: pointer"
                  v-if="!edit_description"
                >
                  <strong>{{ (showTasks[0].so_id != null) ? showTasks[0].so_id : '' }}</strong><br />
                  {{ showTasks[0].description }}
                </div>

                <div class="m-4" v-else>
                  <textarea
                    class="form-control"
                    rows="2"
                    placeholder="Description"
                    v-model="showDescription"
                  ></textarea>
                  <div class="mt-3">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      @click="submitEditDescription(showTasks[0])"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm ms-1"
                      @click="handleRemoveEditDescription()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Description -->

            <!-- Attachment -->
            <div>
              <div class="mb-5">
                <span class="h5"
                  ><i class="fas fa-paperclip"></i> Attachment</span
                >
                <br />
                <div class="mt-2">
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                      v-for="attach in showTasks[0].attachments"
                      :key="attach.id_attach"
                    >
                      <div class="d-flex px-2 py-1">
                        <div>
                          <a :href="getUrl(attach)" target="_blank">
                            <img
                              :src="getAttachment(attach)"
                              class="avatar avatar-xl me-3 position-relative"
                              alt="user1"
                            />
                          </a>
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ attach.name }}</h6>
                          <p class="text-xs text-secondary mb-0">
                            {{ attach.created_at }}
                          </p>
                        </div>
                      </div>
                      <div class="ms-auto text-end">
                        <div class="justify-content-center">
                          <el-dropdown>
                            <span class="el-dropdown-link">
                              <i class="fas fa-caret-down"></i>
                            </span>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item
                                  @click="HandleEditAttachment(attach)"
                                >
                                  Edit
                                </el-dropdown-item>
                                <el-dropdown-item
                                  @click="HandleDeleteAttachment(attach)"
                                >
                                  Delete
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- End Attachment -->

            <!-- Activity  -->
            <div>
              <span class="h5"><i class="fas fa-user-clock"></i> Activity</span>
              <br />
              <div class="mt-3">
                <div class="d-flex form-group">
                  <img
                    src="../../assets/img/team-2.jpg"
                    class="avatar avatar-sm me-3"
                    alt="user1"
                  />
                  <textarea
                    class="form-control"
                    rows="2"
                    v-model="comment"
                    placeholder="Write a comments.."
                  ></textarea>
                </div>
                <button
                  type="button"
                  v-if="comment != ''"
                  class="btn btn-primary btn-sm"
                  @click="handleAddComment()"
                >
                  Submit
                </button>
              </div>
              <div>
                <ul class="list-group">
                  <li
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                    v-for="cmt in showTasks[0].comments"
                    :key="cmt.id_comment"
                  >
                    <div class="d-flex px-2 py-1">
                      <div>
                        <img
                          :src="getImageComment(cmt.image)"
                          class="avatar avatar-sm me-3"
                          alt="user1"
                        />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ cmt.user }}</h6>
                        <p class="text-xs text-secondary mb-0">
                          {{ cmt.created_at }}
                        </p>

                        <div class="mt-2" v-if="cmt.type == 'MP3'">
                          <label>
                            <button class="btn btn-primary btn-sm" @click="handleSound(cmt.description)">
                              <span class="fa fa-play-circle-o fa-lg" v-if="!sound"></span>
                              <span class="fa fa-pause" v-else></span>
                            </button>
                          </label>
                        </div>
                        <div class="mt-2" v-else>
                          {{ cmt.description }}
                        </div>

                      </div>
                    </div>
                    <div class="ms-auto text-end" v-if="cmt.user_id == getUserLoginData.user.id_user">
                      <div>
                        <el-dropdown>
                          <span class="el-dropdown-link">
                            <i class="fas fa-caret-down"></i>
                          </span>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <el-dropdown-item
                                @click="handleEditComments(cmt)"
                                v-if="!cmt.type == 'MP3'"
                              >
                                Edit
                              </el-dropdown-item>
                              <el-dropdown-item
                                @click="handleDeleteComment(cmt)"
                              >
                                Delete
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label>Add To Card</label>
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            @click="showCardMembers(this.$route.params.id)"
          >
            <i class="fas fa-users"></i>
            Members
          </button>
          <!-- <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            @click="showLabels()"
          >
            <i class="fas fa-tags"></i> Labels
          </button> -->
          <button type="button" class="btn btn-secondary w-100 btn-sm">
            <i class="fas fa-check-square"></i> Checklist
          </button>
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            @click="showDates()"
            v-if="!showTasks[0].due_date"
          >
            <i class="fas fa-calendar-alt"></i> Dates
          </button>
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            @click="showAttachment()"
          >
            <i class="fas fa-paperclip"></i> Attachment
          </button>

          <div class="mt-2">
            <label>Actions</label>
            <button type="button" class="btn btn-secondary w-100 btn-sm" @click="handleMove()">
              <i class="fas fa-solid fa-arrow-right"></i> Move
            </button>
            <button type="button" class="btn btn-secondary w-100 btn-sm" @click="handleCopy()">
              <i class="fas fa-calendar-alt"></i> Copy
            </button>
            <button type="button" class="btn btn-secondary w-100 btn-sm" @click="handleProcess()">
              <i class="fas fa-calendar-alt"></i> Archive
            </button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- End Detail Tasks -->
  </div>
</template>


<script>
import { ElNotification } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions, useStore } from "vuex";
import draggable from "vuedraggable";
import axios from "axios";
import useSound from "vue-use-sound";

export default {
  name: "KanbanTransaction",
  components: {
    draggable,
    ArgonButton,
    axios,
    useSound,
  },

  data() {
    return {
      myArray: [{ data: "fazril" }, { data: "ramadhan" }],
      showTasks: {},
      boardCollab: [],
      checkMembers: [],
      move: {
        id_kanban: "",
        id_card: "",
      },
      formEditTasks: {
        title: null
      },
      formMember: {
        id_user: "",
      },
      multipleSelected: [],
      is_loading: false,
      kanban: [],
      boards: [],
      comment: "",
      showDescription: "",
      move_ticket: false,
      copy_ticket: false,
      file: "",
      params: {
        id_kanban: "",
        board_id: "",
        search: "",
        order: "",
        filter: "",
        start_date: "",
        end_date: "",
        date_range: [null, null],
      },
      formCollab: {
        board_id: this.$route.params.id,
        user_id: null,
      },
      form: {
        id_kanban: null,
        board_id: null,
        title: null,
        color: null,
      },
      formTasks: {
        kanban_id: null,
        title: null,
        type: null,
        description: null,
        start_date: new Date(),
        due_date: null,
        so_id: null,
      },
      formTasksDuplicate: {
        kanban_id: null,
        title: null,
        type: null,
        description: null,
        start_date: new Date(),
        due_date: null,
      },
      formCard: {
        id_card: null,
      },
      paramsChangeKanban: {
        id_kanban: "",
        id_card: "",
      },
      update_comment: {
        card_id: "",
        description: "",
      },
      form_comment: {
        card_id: "",
        description: "",
      },
      update_attachment: {
        id_attachment: "",
        name: "",
        beforeName: "",
      },
      id_divisi: "",
      id_card: "",
      user: [],
      delete_id_attachment: "",
      editTasks: false,
      is_table_loading: false,
      add_project_dialog: false,
      add_task_dialog: false,
      rename_project: false,
      remove_project: false,
      detail_tasks: false,
      card_member: false,
      attachment: false,
      label_model: false,
      checklist_model: false,
      edit_comment: false,
      dates_model: false,
      due_date: false,
      edit_description: false,
      edit_attachment: false,
      detailProject: false,
      addBoardCollab: false,
      dataDetailProject: {},
      detailMemberTasks: false,
      dataDetailMemberTasks: [],
      sound: false,
      detailKanban: [],
      detailCollabolator: [],
      boardDuplicate: [],
      listCopy: [],
      listSOS: [],
      qty_modal: false,
      qtys: null,
      users: [
        { userID: 1, name: 'john' },
        { userID: 2, name: 'alice' },
        { userID: 3, name: 'bob' },
        { userID: 4, name: 'charlie' },
      ],
      selectedUser: null,
      selected_user_app: "",
      is_loading_menu_data: false,
      selected_menu_apps: [],
      list_app_menu: [],
      userCollab: [],
    };
  },

  mounted() {
    this.fetchLoaders();
    this.fetchBoardData();
    this.fetchSoListData();
    this.getUserCollab();
  },
  methods: {
    ...mapActions({
      // Board
      fetchBoard: "gopro/fetchBoard",
      fetchCollabolator: "gopro/fetchCollabolator",
      fetchDetailBoard: "gopro/fetchDetailBoard",

      // Kanban
      fetchKanban: "gopro/fetchKanban",
      doCreateKanban: "gopro/doCreateKanban",
      doUpdatedKanban: "gopro/doUpdatedKanban",
      doDeleteKanban: "gopro/doDeleteKanban",
      fetchDetailKanban: "gopro/fetchDetailKanban",

      fetchSOList: "gopro/fetchSOList",
      getMemberUserByBoard: "gopro/getMemberUserByBoard",

      // Card
      fetchCard: "gopro/fetchCard",
      doCreateCard: "gopro/doCreateCard",
      doUpdateCard: "gopro/doUpdateCard",
      doChangeKanban: "gopro/doChangeKanban",
      doDeleteCard: "gopro/doDeleteCard",

      // Attachment
      doCreateAttachment: "gopro/doCreateAttachment",
      doDeleteAttachment: "gopro/doDeleteAttachment",
      doUpdateAttachment: "gopro/doUpdateAttachment",

      // Comment
      doCreateComment: "gopro/doCreateComment",
      doDeleteComment: "gopro/doDeleteComment",

      // Board Collabolator
      fetchBoardCollabolator: "gopro/fetchBoardCollabolator",
      doCreateCardMember: "gopro/doCreateCardMember",
      doCreateCollabolator: "gopro/doCreateCollabolator",
      doDeleteCollabolator: "gopro/doDeleteCollabolator",

      // Fetch User
      fetchUser: "gopro/fetchUser",
      fetchMemberCard: "gopro/fetchMemberCard",

      doDeleteMemberCard: "gopro/doDeleteMemberCard",

      doDeleteBoard: "gopro/doDeleteBoard",
    }),

    addUser(so_id) {
      const so = this.listSOS.find(u => u.so_id === so_id)
      this.formTasks.so_id = so.so_id;
      this.formTasks.title = so.so_id;
      this.formTasks.description = so.item_name + ', qty - ' + so.item_qty;
    },
    changingUser(e) {
      if (!e.inputType) {
        this.addUser(this.selectedUser)
      }
    },

    handleFilterByDone() {
      this.params.filter = 'done';
      this.fetchLoaders();
    },
    handleFilterByProgress() {
      this.params.filter = 'pending';
      this.fetchLoaders();
    },
    handleFilterByAll() {
      this.params.filter = '';
      this.fetchLoaders();
    },


    handleDivisi(event) {
      this.id_divisi = event.target.value;

      const payload = {
        id_user: "",
        id_departement: this.id_divisi,
        search: this.params.search,
        order: this.params.order,
      };

      this.fetchUser(payload)
        .then((response) => {
          if(this.list_app_menu.length == 0) {
            this.list_app_menu = response.data;
          } else {
            let merge = [...response.data, ...this.list_app_menu];
            this.list_app_menu = merge;
          }
          console.log(this.list_app_menu);
          
          // if(this.user.length == 0) {
          //   this.user = response.data;
          // } else {
          //   let merge = [...response.data, ...this.user];
          //   console.log('Get merge per-division.');
          //   console.log(merge);
          //   this.user = merge;
          // }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    formatDate(datestr) {
      const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

      let dates = new Date(datestr);
      let day = dates.getDay();
      let Datee = dates.getDate();
      let month = dates.getMonth() + 1;
      let year = dates.getFullYear();
      let result = weekday[day] + ", " + Datee + "-" + month + "-" + year;

      return result;
    },

    getUserCollab() {
      const payload =  {
        id_board: this.$route.params.id
      }

      this.getMemberUserByBoard(payload)
        .then((response) => {
          this.userCollab = response.data;
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        })
    },

    fetchSoListData() {

      this.fetchSOList()
        .then((response) => {
          this.listSOS = response.result;
          console.log(this.listSOS);
        })
        .catch((err) => {
          console.log(err);
        })
    },

    handleDeleteMemberCard(arg) {
      const payload = {
        card_id: this.showTasks[0].id_card,
        user_id: arg.id_user
      }
      console.log(payload);

      this.doDeleteMemberCard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Remove Success",
            type: "success",
          });
          this.handleShowTasks(this.showTasks[0].id_card);
          this.detailMemberTasks = false;
        })
        .catch((error) => {
          ElNotification({
            title: "Viola!",
            message: "Remove Failed",
            type: "error",
          });
          console.log('error', error);
        })
    },

    removeBoard() {
      const payload = {
        id_board: this.$route.params.id
      };
      const url = 'https://app.gooritss.com/GQGFX/transaksi/boards';
      const dev = 'http://localhost:8080/GQGFX/transaksi/boards';
      this.doDeleteBoard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success delete board!",
            type: "success",
          });
          setTimeout(() => {
            window.location.href = url;
          }, 2500);
          console.log(response);
        })
        .catch((error) => {
          ElNotification({
            title: "Viola!",
            message: "Failed delete board!",
            type: "error",
          });
          console.log(error);
        })
    },

    handleCopy() {
      this.copy_ticket = true;
      this.formTasksDuplicate.title = this.showTasks[0].title;
      this.formTasksDuplicate.description = this.showTasks[0].description;
      this.fetchBoardDuplicate();
    },

    async getList(event) {
      const id_board = event.target.value;
      const payload = {
        id_kanban: this.params.id_kanban,
        board_id: id_board,
        search: this.params.search,
        order: this.params.order,
      };

      await this.fetchKanban(payload)
        .then((response) => {
          this.listCopy = response;
        })
        .catch((err) => {
          console.log(`Error -->> ${JSON.stringify(err)}`);
        });
    },

    addKanban(event) {
      this.formTasksDuplicate.kanban_id = event.target.value;
    },

    submitDuplicate() {
      console.log(this.boardDuplicate);
      const params = {
        kanban_id: this.formTasksDuplicate.kanban_id,
        title: this.formTasksDuplicate.title,
        description: this.formTasksDuplicate.description,
        start_date: this.formTasksDuplicate.start_date,
        due_date: this.formTasksDuplicate.due_date,
      };
      this.doCreateCard(params)
        .then((response) => {
          this.handleCloseAddTaskDuplicate();
          console.log(response);
          ElNotification({
            title: "Viola!",
            message: "Success duplicate task",
            type: "success",
          });
          this.fetchKanbanData();
          setTimeout(() => {
            this.copy_ticket = false;
            this.detail_tasks = false;
          }, 2000);
        })
        .catch((err) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${err.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleMove() {
      this.move_ticket = true;
      this.fetchDetailKanbanData();
    },

    addIdList(event) {
      const id_kanban = event.target.value;
      const id_card = this.showTasks[0].id_card;
      this.move.id_kanban = id_kanban;
      this.move.id_card = id_card;
    },

    submitMove() {
      const payload = {
        kanban_id: this.move.id_kanban,
        id_card: this.move.id_card,
      };

      this.doChangeKanban(payload)
        .then((response) => {
          console.log(response);
          ElNotification({
            title: "Viola!",
            message: "Move Success",
            type: "success",
          });
          this.fetchKanbanData();
          setTimeout(() => {
            this.move_ticket = false;
            this.detail_tasks = false;
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          ElNotification({
            title: "Viola!",
            message: "Move Failed",
            type: "error",
          });
        });
    },

    modalQty() {
      this.qty_modal = true;
    },

    submitQty(arg) {
      let value = arg;

      // if(status == 1) {
      //   value = 0;
      // } else {
      //   value = 1;
      // }

      const payload = {
        id_card: this.showTasks[0].id_card,
        status: value,
        qty: this.qtys
      };

      console.log(payload);

      this.doUpdateCard(payload)
        .then((response) => {
          console.log(response);
          this.handleShowTasks(this.showTasks[0].id_card);
          this.qty_modal = false;
          this.qtys = 0;
        })
        .catch((error) => {
          console.log(error);
        })
    },

    updateChecklist(arg) {
      console.log(arg.target.value);
      const status = arg.target.value;
      let value = "";

      if(status == 1) {
        value = 0;
      } else {
        value = 1;
      }

      if(this.showTasks[0].so_id != null && this.showTasks[0].status == 1) {
        const payload = {
          id_card: this.showTasks[0].id_card,
          status: 0
        };

        this.doUpdateCard(payload)
          .then((response) => {
            console.log(response);
            this.handleShowTasks(this.showTasks[0].id_card);
          })
          .catch((error) => {
            console.log(error);
          })
      } else if(this.showTasks[0].so_id != null && this.showTasks[0].status == 0) {
        this.qty_modal = true;
      } else {
        const payload = {
          id_card: this.showTasks[0].id_card,
          status: value
        };

        this.doUpdateCard(payload)
          .then((response) => {
            console.log(response);
            this.handleShowTasks(this.showTasks[0].id_card);
          })
          .catch((error) => {
            console.log(error);
          })
      }
    }, 

    handleChangeStartDate() {
      if (this.params.date_range == null) {
        this.params.date_range = ["", ""];
      }

      this.params.start_date = this.params.date_range[0];
      this.params.end_date = this.params.date_range[1];
    },

    handleUpdateDueDate(arg) {
      const payload = {
        due_date: this.due_date,
        id_card: arg,
      };

      this.doUpdateCard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Update Success",
            type: "success",
          });
          this.dates_model = false;
          this.due_date = false;
          this.handleShowTasks(this.showTasks[0].id_card);
        })
        .catch((error) => {
          console.log(error);
        })
    },

    handleUpdateTasks() {
      const payload = {
        id_card: this.showTasks[0].id_card,
        title: this.formEditTasks.title,
      };

      this.doUpdateCard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Update Success",
            type: "success",
          });
          this.editTasks = false;
          this.handleShowTasks(this.showTasks[0].id_card);
        })
        .catch((error) => {
          console.log(error);
        })
    },

    changeEditTasks(arg) {
      this.formEditTasks.title = arg;
      if(this.editTasks) {
        this.editTasks = false;
      } else {
        this.editTasks = true;
      }
    },

    handleChooseTicket(event) {
      this.id_card = event.target.value;
    },

    handleSound(sound) {
      const lagu = `https://goopro.erakomp.co.id/ns/voiceNote/${sound}`;
      if(sound) {        
        var audio = new Audio(lagu);
        audio.play();
      }
    },

    fetchBoardData() {
      const payload = {
        id_board: this.$route.params.id,
        search: "",
        order: "",
      };

      this.fetchBoard(payload)
        .then((response) => {
          this.boards = response.data[0];
          console.log(this.boards);
        })
        .catch((error) => {
          console.log(error);
        })
    },

    fetchBoardDuplicate() {
      const payload = {
        id_board: "",
        search: "",
        order: "",
      };

      this.fetchBoard(payload)
        .then((response) => {
          this.boardDuplicate = response.data;
          console.log(this.boards);
        })
        .catch((error) => {
          console.log(error);
        })
    },

    clearDetailCollab() {
      this.detailCollabolator = null;
    },

    handleDeleteComment(arg) {
      const payload = {
        id_comment: arg.id_comment
      };

      this.doDeleteComment(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Remove Success",
            type: "success",
          });
          this.handleShowTasks(this.showTasks[0].id_card);
        })
        .catch((error) => {
          console.log(error);
        })
    },

    handleMemberCards() {

      const payload = {
        card_id: this.showTasks[0].id_card,
        user_id: this.id_card
      };

      this.doCreateCardMember(payload)
        .then((response) => {
          if(response.status) {
            ElNotification({
              title: "Viola!",
              message: "Success",
              type: "success",
            });
            this.handleShowTasks(this.showTasks[0].id_card);
            this.card_member = false;
          } else {
            ElNotification({
              title: "Viola!",
              message: response.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          ElNotification({
            title: "Viola!",
            message: "Error",
            type: "error",
          });
        });
    },

    handleAssignTo(event) {
      this.formCollab.user_id = null;

      setTimeout(() => {
        this.formCollab.user_id = event.target.value;
      }, 1500);
    },

    detailMemberTicket(arg) {
      this.dataDetailMemberTasks = [];
      const payload = {
        card_id: arg.id_card
      };
      
      this.fetchMemberCard(payload)
        .then((response) => {
          this.dataDetailMemberTasks = response.data;
          this.detailMemberTasks = true;
        })
        .catch((error) => {
          console.log(error);
        })
    },

    handleDivisi(event) {
      this.id_divisi = event.target.value;

      const payload = {
        id_user: "",
        id_departement: this.id_divisi,
        search: "",
        order: "",
      };

      this.fetchUser(payload)
        .then((response) => {
          this.user = response.data;
        })
        .catch((err) => {
          console.log("Error", err);
          this.user = [];
          ElNotification({
            title: "Viola!",
            message: "Error",
            type: "error",
          });
        });
    },

    submitAddCollabolator() {
      const payload = {
        board_id: this.formCollab.board_id,
        user_id: this.formCollab.user_id,
      };

      this.doCreateCollabolator(payload)
        .then((response) => {
          if(response.status) {
            ElNotification({
              title: "Viola!",
              message: "Success",
              type: "success",
            });
            this.formCollab.user_id = "";
            setTimeout(() => {
              window.location.href =
                "https://app.gooritss.com" + this.$route.fullPath;
            }, 4000);
          } else {
            ElNotification({
              title: "Viola!",
              message: response.message,
              type: "error",
            });
            this.formCollab.user_id = "";
          }
        })
        .catch((err) => {
          ElNotification({
            title: "Viola!",
            message: "Error",
            type: "error",
          });
          this.formCollab.user_id = "";
        });
    },

    handleAddCollabolator() {
      this.addBoardCollab = true;
    },

    checkMove(id_kanban, id_card) {
      // this.paramsChangeKanban.id_kanban = id_kanban;
      // this.paramsChangeKanban.id_card = id_card;
      var map = {
        event: id_card,
        myKanbanId: id_kanban,
      };
      if (map.event.added != null) {
        const payload = {
          kanban_id: map.myKanbanId,
          id_card: map.event.added.element.id_card,
        };

        this.doChangeKanban(payload)
          .then((response) => {
            console.log(response);
            this.fetchKanbanData();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    handleDeleteCollabolator(arg) {
      const payload = {
        id_board_collabolator: arg,
      };

      this.doDeleteCollabolator(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          this.detailProject = false;
        })
        .catch((err) => {
          ElNotification({
            title: "Viola!",
            message: "Error",
            type: "error",
          });
        });
    },

    handleDetailProject() {
      this.dataDetailProject = null;
      
      const payload = {
        id_board: this.$route.params.id,
      };

      this.fetchDetailBoard(payload)
        .then((response) => {
          this.detailProject = true;
          this.dataDetailProject = response;
          console.log(response);
          this.fetchDetailKanbanData();
          this.fetchCollabolatorData();
        })
        .catch((error) => {
          alert(error);
        });
    },

    submitEditDescription(id_card) {
      let id = id_card.id_card;
      const payload = {
        id_card: id,
        description: this.showDescription,
      };
      this.doUpdateCard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          this.edit_description = false;
          this.showDescription = '';
          this.handleShowTasks(this.showTasks[0].id_card);
        })
        .catch((err) => {
          ElNotification({
            title: "Viola!",
            message: "Error",
            type: "error",
          });

        });
    },

    fetchDetailKanbanData() {
      const payload = {
        id_kanban: this.params.id_kanban,
        board_id: this.$route.params.id,
        search: this.params.search,
        order: this.params.order,
      };

      this.fetchDetailKanban(payload)
        .then((response) => {
          this.detailKanban = response
        })
        .catch((error) => {
          console.log(error);
        });
    }, 

    fetchCollabolatorData() {
      let paramsId = this.$route.params.id;
      const payload = {
        id_board_collabolator: null,
        board_id: paramsId,
        search: this.params.search,
        order: this.params.order,
      };

      this.fetchBoardCollabolator(payload)
        .then((response) => {
          console.log('hello world fetch board');
          this.detailCollabolator = response;
          console.log(this.detailCollabolator);
          this.list_app_menu = response;
          console.log(this.list_app_menu);
          console.log('FETCH');
        })
        .catch((error) => {
          console.log(error);
        })
    },

    async fetchLoaders() {
      this.is_loading = true;
      const payload = {
        id_kanban: this.params.id_kanban,
        board_id: this.$route.params.id,
        search: this.params.search,
        order: this.params.order,
        filter: this.params.filter,
        start_date: this.params.start_date,
        end_date: this.params.end_date,
      };

      await this.fetchKanban(payload)
        .then((response) => {
          this.kanban = response;
          this.is_loading = false;
        })
        .catch((err) => {
          this.is_loading = false;
          console.log(`Error -->> ${JSON.stringify(err)}`);
        });
    },

    async fetchKanbanData() {
      // this.is_loading = true;
      const payload = {
        id_kanban: this.params.id_kanban,
        board_id: this.$route.params.id,
        search: this.params.search,
        order: this.params.order,
        filter: this.params.filter,
        start_date: this.params.start_date,
        end_date: this.params.end_date,
      };

      await this.fetchKanban(payload)
        .then((response) => {
          this.kanban = response;
          // this.is_loading = false;
        })
        .catch((err) => {
          this.is_loading = false;
          console.log(`Error -->> ${JSON.stringify(err)}`);
        });
    },

    getDate(date) {
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let dates = new Date(date);
      let day = dates.getDate();
      let month = dates.getMonth();
      var year = dates.getFullYear();
      
      // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
      let now = new Date();
      let exam = "";
      if (dates > now) {
        exam = "on going";
      } else {
        exam = "over due";
      }

      let result = day + "/" + monthNames[month] + "/" + year ;
      return result;
    },
    
    getDateDue(date) {
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let dates = new Date(date);
      let day = dates.getDate();
      let month = dates.getMonth();
      var year = dates.getFullYear();
      const minute = 1000 * 60;
      const hour = minute * 60;
      const days = hour * 24;
      const years = days * 365;
      let time = dates.getHours() + ":" + dates.getMinutes();
      let hasil = Math.round(dates.getTime() / year);



      // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
      let now = new Date();
      let exam = "";
      if (dates > now) {
        exam = "on going";
      } else {
        exam = "over due";
      }

      let result = day + "/" + monthNames[month] + "/" + year + " : " + time;
      return result;
    },

    getColorDueDate(date, status) {
      let exam = "";
      if(date == null || date == '') {
        exam = "btn-danger"
        return exam;
      }
      let dates = new Date(date);
      let day = dates.getDay();
      let month = dates.getMonth();
      let now = new Date();
      if (dates > now && status == 0) {
        exam = "bg-gradient-info";
        return exam;
      } else if(dates == now && status == 0) {
        exam = "bg-gradient-warning";
        return exam;
      } else if(status == 1) {
        exam = "bg-gradient-success";
        return exam;
      } else {
        exam = "bg-gradient-danger"
        return exam;
      }

      
    },

    getColor(date, status) {
      // let dates = new Date(date);
      // let day = dates.getDay();
      // let month = dates.getMonth();
      // let now = new Date();
      // let exam = "";
      // if(status == 1) {
      //   exam = "bg-gradient-success";
      //   return exam;
      // } else {
      //   exam = "bg-gradient-info"
      //   return exam;
      // }

      let exam = "";
      if(date == null || date == '') {
        exam = "btn-danger"
        return exam;
      }
      let dates = new Date(date);
      let day = dates.getDay();
      let month = dates.getMonth();
      let now = new Date();
      if (dates > now && status == 0) {
        exam = "bg-gradient-info";
        return exam;
      } else if(dates == now && status == 0) {
        exam = "bg-gradient-warning";
        return exam;
      } else if(dates == now) {
        exam = "bg-gradient-warning";
        return exam;
      } else if(status == 1) {
        exam = "bg-gradient-success";
        return exam;
      } else if(dates > now) {
        exam = "bg-gradient-info"
        return exam;
      } else {
        exam = "bg-gradient-danger"
        return exam;
      }
    },

    submitMemberCard() {
      let data = this.formMember.id_user;
      alert(this.formMember.id_user);
    },

    showLabels() {
      this.label_model = true;
    },

    showDates() {
      this.dates_model = true;
    },

    handleSubmitDates() {
      confirm("Fitur Masih dibuat");
    },

    handleProcess() {
      ElNotification({
        title: "Viola!",
        message: "Fitur masih dalam process",
        type: "info",
        timer: 4000
      });
    },

    handleRemoveEditDescription() {
      this.edit_description = false;
    },

    HandleEditAttachment(event) {
      this.update_attachment.id_attachment = event.id_attachment;
      this.update_attachment.beforeName = event.name;
      this.edit_attachment = true;
    },

    HandleSubmitUpdateAttachment() {
      const payload = {
        id_attachment: this.update_attachment.id_attachment,
        beforeName: this.update_attachment.beforeName,
        name: this.update_attachment.name,
      };

      confirm(JSON.stringify(payload));
    },

    handleDeleteTasks(arg) {

      const payload = {
        id_card: arg,
      };

      this.doDeleteCard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          this.fetchKanbanData();
        })
        .catch((err) => {
          ElNotification({
            title: "Viola!",
            message: "Error",
            type: "error",
          });
        });
    },

    HandleDeleteAttachment(event) {
      this.delete_id_attachment = event.id_attachment;
      confirm("Apakah anda ingin delete attachment tersebut?");
      const payload = {
        id_attachment: this.delete_id_attachment,
      };

      this.doDeleteAttachment(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          this.delete_id_attachment = "";
          this.handleShowTasks(this.showTasks[0].id_card);
        })
        .catch((err) => {
          ElNotification({
            title: "Viola!",
            message: "Error",
            type: "error",
          });
          console.log(JSON.stringify(err.message));
        });
    },

    handleEditDescription(arg) {
      this.edit_description = true;
      this.showDescription = arg;
    },

    onChangeFileUpload(event) {
      let test = event.target.files[0];
      this.file = test;
    },

    // Detail Click Card

    // Handle Add Comment
    handleAddComment() {
      this.form_comment.card_id = this.showTasks[0].id_card;
      this.form_comment.description = this.comment;

      let payload = {
        card_id: this.form_comment.card_id,
        description: this.form_comment.description,
        user_id: this.getUserLoginData.user.id_user
      };

      this.doCreateComment(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          this.form_comment.description = "";
          this.handleShowTasks(this.form_comment.card_id);
        })
        .catch((err) => {
          ElNotification({
            title: "Error!",
            message: "Error",
            type: "error",
          });
        });
    },
    // End Handle Add Comment

    showCardMembers(params) {
      let paramsId = this.$route.params.id;
      const payload = {
        id_board_collabolator: null,
        board_id: paramsId,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchBoardCollabolator(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          this.boardCollab = response;
          this.card_member = true;
        })
        .catch((err) => {
          alert(JSON.stringify(err.message));
        });
    },

    getAttachment(img) {
      let gambar = "";
      if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG") {
        gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
      } else {
        gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
      }

      return gambar;
    },

    getUrl(img) {
      let gambar = "";
      if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG" || img.type == "PDF" || img.type == "DOC" || img.type == 'NO_FILE') {
        gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
      } else {
        gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
      }

      return gambar;
    },

    getImageComment(img) {
      // return `https://salesforce.klikpersada.co.id/ns/employee/${img}`;
      return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
    },

    handleEditComments(arg) {
      this.edit_comment = true;
      this.update_comment.description = arg.description;
      this.update_comment.card_id = arg.card_id;
    },

    selectFile() {
      this.$refs.image.click();
    },

    showAttachment() {
      this.attachment = true;
      this.file = null;
    },

    handleSubmitAttachment() {
      let id_card = this.showTasks[0].id_card;
      let file = this.file;

      let formData = new FormData();
      formData.append("file", file);
      formData.append("card_id", id_card);
      axios
        .post("https://goopro.erakomp.co.id/api/attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((results) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          this.file = "";
          this.attachment = false;
          this.handleShowTasks(this.showTasks[0].id_card);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // End Detail Click Card

    handleShowTasks(data) {
      this.formCard.id_card = data;
      let payload = {
        id_card: this.formCard.id_card,
        search: null,
        order: null,
      };

      this.fetchCard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          console.log(response);
          this.showTasks = response;
          this.detail_tasks = true;
          this.fetchKanbanData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error!",
            message: "Error",
            type: "error",
          });
        });
    },

    handleRenameKanban() {
      console.log(this.form);
      const params = {
        id_kanban: this.form.id_kanban,
        board_id: this.form.board_id,
        title: this.form.title,
        color: this.form.color,
      };
      this.doUpdatedKanban(params)
        .then((response) => {
          this.rename_project = false;
          ElNotification({
            title: "Viola!",
            message: "Success update kanban!",
            type: "success",
          });
          this.fetchKanbanData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleRemoveProject() {
      console.log(this.form);
      const params = {
        id_kanban: this.form.id_kanban,
        board_id: this.form.board_id,
        title: this.form.title,
        color: this.form.color,
      };

      this.doDeleteKanban(params)
        .then((response) => {
          this.remove_project = false;
          ElNotification({
            title: "Viola!",
            message: "Success delete kanban!",
            type: "success",
          });
          console.log(response);
          this.fetchKanbanData();
        })
        .catch((err) => {
          ElNotification({
            title: "Viola!",
            message: "Success delete kanban!",
            type: "error",
          });
          console.log(err);
        });
    },

    handleCreateTasks() {
      let params;

      if(this.formTasks.so_id != null) {
        params = {
          kanban_id: this.formTasks.kanban_id,
          title: this.formTasks.title,
          description: this.formTasks.description,
          start_date: this.formTasks.start_date,
          due_date: this.formTasks.due_date,
          so_id: this.formTasks.so_id,
          member: this.selected_menu_apps,
        }
      } else { 
        params = {
          kanban_id: this.formTasks.kanban_id,
          title: this.formTasks.title,
          description: this.formTasks.description,
          start_date: this.formTasks.start_date,
          due_date: this.formTasks.due_date,
          member: this.selected_menu_apps,
        }
      }

      this.doCreateCard(params)
        .then((response) => {
          this.handleCloseAddTask();
          this.add_task_dialog = false;
          ElNotification({
            title: "Viola!",
            message: "Success create task",
            type: "success",
          });
          this.fetchKanbanData();
        })
        .catch((err) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${err.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleCreateKanban() {
      console.log("Hello World");
      const params = {
        board_id: this.$route.params.id,
        title: this.form.title,
        color: this.form.color,
      };
      this.doCreateKanban(params)
        .then((response) => {
          this.handleCloseAddTask();
          this.add_project_dialog = false;
          this.form.title = "";
          ElNotification({
            title: "Voila!",
            message: "Success create project!",
            type: "success",
          });
          console.log("____Results ", response);
          this.fetchKanbanData();
        })
        .catch((err) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${err.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    // Handle Open Dialog
    handleOpenAddProject() {
      this.add_project_dialog = true;
    },

    handleOpenTask(id_kanban) {
      this.formTasks.kanban_id = id_kanban;
      console.log("kanban_id", this.formTasks.kanban_id);
      this.add_task_dialog = true;
    },

    handleOpenRenameProject(data) {
      this.rename_project = true;
      this.form.id_kanban = data.id_kanban;
      this.form.board_id = data.board_id;
      this.form.title = data.title;
      this.form.color = data.color;
    },

    handleOpenRemoveProject(data) {
      this.remove_project = true;
      this.form.id_kanban = data.id_kanban;
      this.form.board_id = data.board_id;
      this.form.title = data.title;
      this.form.color = data.color;
    },

    // Handle Close
    handleCloseAddProject() {
      console.log("Hello World");
    },

    handleCloseAddTask() {
      this.formTasks.title = null;
      this.formTasks.type = null;
      this.formTasks.description = null;
      this.formTasks.start_date = null;
      this.formTasks.due_date = null;
      this.selected_menu_apps = [];
    },

    handleCloseAddTaskDuplicate() {
      this.formTasksDuplicate.title = null;
      this.formTasksDuplicate.description = null;
      this.formTasksDuplicate.start_date = null;
      this.formTasksDuplicate.due_date = null;
    },

    handleCloseRenameProject() {
      console.log("Hello World");
    },

    handleCloseRemoveProject() {
      console.log("Remove Project");
    },
  },

  computed: {
    getKanban() {
      return this.$store.state.gopro.kanban;
    },
    getBoardCollabolator() {},
    getUserData() {
      return this.$store.state.eraforce.user_data;
    },
    getDivisi() {
      return this.$store.state.hrd.divisi;
    },
    getUserLoginData() {
      return this.$store.state.auth.login_data;
    },
  },
};
</script>


<style scoped>
.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 93px;
  height: 93px;
  text-align: left;
}
.ribbon span {
  font-size: 0.8rem;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  transform: rotate(45deg);
  width: 125px;
  display: block;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 17px;
  right: -29px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #79a70a;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.foo {
  clear: both;
}

.bar {
  content: "";
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.baz {
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 2em;
  transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 100px;
  left: 1000px;
}
</style>