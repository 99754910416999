<template>
    <!-- Hello World {{ getReporting }} -->
    <div class="mx-4">
      <div class="card p-3 mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <span class="text-bold"> Employee Tasks Reporting </span>
          </div>
          <div>

            <select class="form-select" @change="getDivision($event)">
              <option selected disabled>Select Division</option>
              <option v-for="(items, index) in getDivisi" :key="index" :value="items.id_departement">{{ items.departement }}</option>
            </select>

            <el-date-picker
              type="daterange"
              v-model="date_range"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              size="large"
              style="margin-top: 2px; margin-right: 10px"
              value-format="YYYY-MM-DD"
              @change="handleChangeStartDate"
            />
            <argon-button
                variant="gradient"
                color="primary"
                size="md"
                @click="fetchDate"
            >
                Show Data
            </argon-button> |
            <argon-button
                variant="gradient"
                color="success"
                size="md"
                @click="exportExcel"
            >
                Export Excel
            </argon-button>
          </div>
        </div>
      </div>
  
      <div class="card p-3" v-if="is_loading">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
  
      <div class="card p-3" v-else>
        <div class="table-responsive">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  No
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Fullname
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Done
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  On Time
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Over Due
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Progress
                </th>
                
                
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in so" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <span class="text-xs text-secondary mb-0">{{ index + 1 }}</span>
                  </div>
                </td>
                <td class="align-middle text-center text-sm">
                  <p class="text-xs text-secondary mb-0">
                    {{ item.fullname }}
                  </p>
                </td>
                <td class="align-middle text-center text-sm">
                  <button class="btn btn-sm btn-primary" @click="detailTicket(item.done, item.fullname)" :disabled="item.done.length == 0">{{ item.done.length }}</button>
                </td>
                <td class="align-middle text-center text-sm">
                  <button class="btn btn-sm btn-primary" @click="detailTicket(item.notOverDue, item.fullname)" :disabled="item.notOverDue.length == 0">{{ item.notOverDue.length }}</button>
                </td>
                <td class="align-middle text-center text-sm">
                  <button class="btn btn-sm btn-primary" @click="detailTicket(item.overDue, item.fullname)" :disabled="item.overDue.length == 0">{{ item.overDue.length }}</button>
                </td>
                <td class="align-middle text-center text-sm">
                  <button class="btn btn-sm btn-primary" @click="detailProgress(item.proccess, item.fullname)" :disabled="item.proccess.length == 0">{{ item.proccess.length }}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <el-dialog
        v-model="ModalDetailTasks"
        :title="'Detail Employee Tasks : ' + nm_user "
        width="50%"
        >
        <span>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Descirption</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Complete Date</th>
                    <th scope="col">Periode</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in detailTasks" :key="index">
                    <th scope="row">{{ index + 1}}</th>
                    <td>
                      <strong><p style="cursor: pointer;" class="text-primary" @click="handleShowTasks(item.id_card)">{{ item.title }}</p></strong>
                    </td>
                    <td>{{ item.description }}</td>
                    <td>{{ dateFormat(item.start_date) }}</td>
                    <td>{{ dateFormat(item.due_date) }}</td>
                    <td>{{ (item.complete_date != null) ? dateFormat(item.complete_date) : '--' }}</td>
                    <td>
                      <!-- <span :class="'badge ' + periode(item.due_date, item.complete_date)">{{ calculateDatePeriod(item.due_date, item.complete_date, 'hours') }} Jam {{ calculateDatePeriod(item.due_date, item.complete_date, 'minutes') }} Menit</span> -->
                      <span :class="'badge ' + periode(item.due_date, item.complete_date)">{{ calculateDatePeriod(item.due_date, item.complete_date) }}</span>
                      <!-- <span :class="'badge ' + periode(item.due_date, item.complete_date)">{{ daysSinceLastDate(item.due_date, item.complete_date) }}</span> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </span>
      </el-dialog>
      
      <!-- End Modal Detail Tasks-->
      <el-dialog
        v-model="ModalDetailTasksProgress"
        :title="'Detail Employee Tasks : ' + nm_user "
        width="50%"
        >
        <span>
          <p class="text-right">Date Now: {{ dateFormat(new Date()) }}</p>
          <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Descirption</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Periode</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in detailProcess" :key="index">
                    <th scope="row">{{ index + 1}}</th>
                    <td>{{ item.title }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ dateFormat(item.start_date) }}</td>
                    <td>{{ dateFormat(item.due_date) }}</td>
                    <td>
                      <!-- <span :class="'badge ' + periode(item.due_date, item.complete_date)">{{ calculateDatePeriod(item.due_date, item.complete_date, 'hours') }} Jam {{ calculateDatePeriod(item.due_date, item.complete_date, 'minutes') }} Menit</span> -->
                      <span :class="'badge ' + periodeProgress(item.due_date, new Date())">{{ calculateDatePeriodProgress(item.due_date, new Date()) }}</span>
                      <!-- <span :class="'badge ' + periode(item.due_date, item.complete_date)">{{ daysSinceLastDate(item.due_date, item.complete_date) }}</span> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </span>
      </el-dialog>

      <!-- Detail Tasks -->
      <el-dialog v-model="detail_tasks" title="Detail Tasks" width="60%">
        <div class="row">
          <div class="col-md-9">
            <p>Created By <strong>{{ (showTasks[0].user.length > 0) ? showTasks[0].user[0].fullname : '--' }}</strong></p>
            <div class="card card-primary card-outline shadow p-3">
              <div class="mb-5">
                <div class="h5"
                  ><i class="fas fa-chalkboard"></i> {{ showTasks[0].title }}
                </div>
                <!-- <div class="row">
                  <div class="col-md-8">
                    <i class="fas fa-chalkboard"></i>
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                  </div>
                </div> -->
                <!-- <span class="h5">
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                </span> -->
                <br />
                <span>in List</span>

                <!-- Member Card -->
                <div class="m-3" v-if="showTasks[0].member_card.length > 0">
                  <h6>Member Ticket</h6>
                  <div class="d-flex">
                    <div class="avatar-group ms-2">
                      <a
                        href="#"
                        @click="detailMemberTicket(showTasks[0])"
                        class="avatar avatar-xs border-0 rounded-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        v-for="thems in showTasks[0].member_card"
                        :key="thems.id"
                        style="pointer: cursor;"
                      >
                        <img
                          alt="Image placeholder"
                          src="../../../assets/img/team-3.jpg"
                        />
                      </a>
                    </div>
                    <div>
                      Total Members: {{ showTasks[0].member_card.length }}
                    </div>
                  </div>
                </div>
                <!-- End Member Card -->

                <!-- Due Date -->
                <div class="m-3">
                  <div class="row">
                    <div class="col-md-6">
                      <h6>Start Date</h6>
                      <button type="button" class="btn btn-info btn-sm">
                        <i class="fas fa-clock"></i>
                        {{ getDate(showTasks[0].start_date) }}
                      </button>
                    </div>
                    <div class="col-md-6">
                      <h6>Due Date</h6>
                      <!-- <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="showTasks[0].status" v-if="showTasks[0].status == 0 || showTasks[0].status == '' || showTasks[0].status == null" @change="updateChecklist($event)" />
                        <input class="form-check-input" type="checkbox" :value="showTasks[0].status" v-if="showTasks[0].status == 1" checked @change="updateChecklist($event)" />
                        
                      </div> -->
                      <button v-if="showTasks[0].due_date" type="button" :class="getColorDueDate(showTasks[0].due_date, showTasks[0].status) + ' btn btn-sm'">
                          <i class="fas fa-clock"></i>
                          {{ getDateDue(showTasks[0].due_date) }}
                        </button>
                        <button v-else type="button" class="btn btn-sm btn-warning" @click="showDates()">
                          <i class="fas fa-clock"></i>
                          Update Due Date
                        </button>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="flexCheckChecked" disabled :value="showTasks[0].status" v-if="showTasks[0].status == 0 || showTasks[0].status == '' || showTasks[0].status == null" @change="updateChecklist($event)" />
                        <input class="form-check-input" type="checkbox" id="flexCheckChecked" disabled :value="showTasks[0].status" v-if="showTasks[0].status == 1" checked @change="updateChecklist($event)" />
                        <label class="form-check-label" for="flexCheckChecked">
                          Complete
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Description -->
              <div>
                <div class="mb-5">
                  <span class="h5">
                    <i class="fas fa-th-list"></i> Description</span>
                  <br />
                  <div
                    class="m-4"
                    style="cursor: pointer"
                    v-if="!edit_description"
                  >
                    {{ showTasks[0].description }}
                  </div>

                  <div class="m-4" v-else>
                    <textarea
                      class="form-control"
                      rows="2"
                      placeholder="Description"
                      v-model="showDescription"
                    ></textarea>
                    <div class="mt-3">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="submitEditDescription(showTasks[0])"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm ms-1"
                        @click="handleRemoveEditDescription()"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Description -->

              <!-- Attachment -->
              <div>
                <div class="mb-5">
                  <span class="h5"
                    ><i class="fas fa-paperclip"></i> Attachment</span
                  >
                  <br />
                  <div class="mt-2">
                    <ul class="list-group">
                      <li
                        class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                        v-for="attach in showTasks[0].attachments"
                        :key="attach.id_attach"
                      >
                        <div class="d-flex px-2 py-1">
                          <div>
                            <a :href="getUrl(attach)" target="_blank">
                              <img
                                :src="getAttachment(attach)"
                                class="avatar avatar-xl me-3 position-relative"
                                alt="user1"
                              />
                            </a>
                          </div>
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{ attach.name }}</h6>
                            <p class="text-xs text-secondary mb-0">
                              {{ attach.created_at }}
                            </p>
                          </div>
                        </div>
                        <div class="ms-auto text-end">
                          <div class="justify-content-center">
                            <el-dropdown>
                              <span class="el-dropdown-link">
                                <i class="fas fa-caret-down"></i>
                              </span>
                              <template #dropdown>
                                <el-dropdown-menu>
                                  <el-dropdown-item
                                    @click="HandleEditAttachment(attach)"
                                  >
                                    Edit
                                  </el-dropdown-item>
                                  <el-dropdown-item
                                    @click="HandleDeleteAttachment(attach)"
                                  >
                                    Delete
                                  </el-dropdown-item>
                                </el-dropdown-menu>
                              </template>
                            </el-dropdown>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- End Attachment -->

              <!-- Activity  -->
              <div>
                <span class="h5"><i class="fas fa-user-clock"></i> Activity</span>
                <br />
                <div>
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                      v-for="cmt in showTasks[0].comments"
                      :key="cmt.id_comment"
                    >
                      <div class="d-flex px-2 py-1">
                        <div>
                          <img
                            :src="getImageComment(cmt.image)"
                            class="avatar avatar-sm me-3"
                            alt="user1"
                          />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ cmt.user }}</h6>
                          <p class="text-xs text-secondary mb-0">
                            {{ cmt.created_at }}
                          </p>

                          <div class="mt-2" v-if="cmt.type == 'MP3'">
                            <label>
                              <button class="btn btn-primary btn-sm" @click="handleSound(cmt.description)">
                                <span class="fa fa-play-circle-o fa-lg" v-if="!sound"></span>
                                <span class="fa fa-pause" v-else></span>
                              </button>
                            </label>
                          </div>
                          <div class="mt-2" v-else>
                            {{ cmt.description }}
                          </div>

                        </div>
                      </div>
                      <div class="ms-auto text-end">
                        <div>
                          <el-dropdown>
                            <span class="el-dropdown-link">
                              <i class="fas fa-caret-down"></i>
                            </span>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item
                                  disabled
                                  v-if="!cmt.type == 'MP3'"
                                >
                                  Edit
                                </el-dropdown-item>
                                <el-dropdown-item
                                disabled
                                >
                                  Delete
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <label>Add To Card</label>
            <button
              type="button"
              class="btn btn-secondary w-100 btn-sm"
              disabled
            >
              <i class="fas fa-users"></i>
              Members
            </button>
            <!-- <button
              type="button"
              class="btn btn-secondary w-100 btn-sm"
              @click="showLabels()"
            >
              <i class="fas fa-tags"></i> Labels
            </button> -->
            <button type="button" class="btn btn-secondary w-100 btn-sm" disabled>
              <i class="fas fa-check-square"></i> Checklist
            </button>
            <button
              type="button"
              class="btn btn-secondary w-100 btn-sm"
              disabled
            >
              <i class="fas fa-calendar-alt"></i> Dates
            </button>
            <button
              type="button"
              class="btn btn-secondary w-100 btn-sm"
              disabled
            >
              <i class="fas fa-paperclip"></i> Attachment
            </button>

            <div class="mt-2">
              <label>Actions</label>
              <button type="button" class="btn btn-secondary w-100 btn-sm" disabled @click="handleMove()">
                <i class="fas fa-solid fa-arrow-right"></i> Move
              </button>
              <button type="button" class="btn btn-secondary w-100 btn-sm" disabled @click="handleCopy()">
                <i class="fas fa-calendar-alt"></i> Copy
              </button>
              <button type="button" class="btn btn-secondary w-100 btn-sm" disabled @click="handleProcess()">
                <i class="fas fa-calendar-alt"></i> Archive
              </button>
            </div>
          </div>
        </div>
      </el-dialog>
      <!-- End Detail Tasks -->
    </div>
  </template>
  
  <script>
  import { ElNotification } from "element-plus";
  import ArgonButton from "@/components/ArgonButton.vue";
  import { mapActions, useStore } from "vuex";
  import { format, startOfMonth, endOfMonth, isDate, parse } from "date-fns";
  import axios from "axios";
  
  export default {
    name: "GoappsAdminReporting",
    components: {
      ArgonButton,
    },
  
    data() {
      return {
        reporting: [],
        is_loading: false,
        is_table_loading: false,
        start_date: '',
        end_date: '',
        division: null,
        so: [],
        detailSo: [],
        ModalDetailPO: false,
        date_range: [null, null],
        detailTasks: [],
        detailProcess: [],
        ModalDetailTasks: false,
        ModalDetailTasksProgress: false,
        nm_user: null,
        detailMember: null,
        detail_card: false,
        detail_tasks: false,
        showTasks: {},
      };
    },
  
    mounted() {
      this.fetchSoReportingData();
    },
  
    methods: {
      ...mapActions({
        fetchCard: "gopro/fetchCard",
        fetchReporting: "gopro/fetchReporting",
        doExportReport: "gopro/doExportReport",
        fetchSO: "gopro/fetchSO",
        doFetchReportingTasks: "gopro/doFetchReportingTasks",
        fetchEmployeeTasksReporting: "gopro/fetchEmployeeTasksReporting",
        exportEmployeeTasksReporting: "gopro/exportEmployeeTasksReporting",
  
        fetchBoard: "gopro/fetchBoard",
        fetchKanban: "gopro/fetchKanban",
      }),

      dateFormat(datestr) {
        if (datestr) return format(new Date(datestr), "eeee, dd-MMMM-yyyy  HH:mm:ss:aa");
  
        return "";
      },

      // Show Tasks
      getDate(date) {
        const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let dates = new Date(date);
        let day = dates.getDate();
        let month = dates.getMonth();
        var year = dates.getFullYear();
        
        // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
        let now = new Date();
        let exam = "";
        if (dates > now) {
          exam = "on going";
        } else {
          exam = "over due";
        }

        let result = day + "/" + monthNames[month] + "/" + year ;
        return result;
      },

      getDateDue(date) {
        const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let dates = new Date(date);
        let day = dates.getDate();
        let month = dates.getMonth();
        var year = dates.getFullYear();
        const minute = 1000 * 60;
        const hour = minute * 60;
        const days = hour * 24;
        const years = days * 365;
        let time = dates.getHours() + ":" + dates.getMinutes();
        let hasil = Math.round(dates.getTime() / year);



        // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
        let now = new Date();
        let exam = "";
        if (dates > now) {
          exam = "on going";
        } else {
          exam = "over due";
        }

        let result = day + "/" + monthNames[month] + "/" + year + " : " + time;
        return result;
      },

      getColorDueDate(date, status) {
        let exam = "";
        if(date == null || date == '') {
          exam = "btn-danger"
          return exam;
        }
        let dates = new Date(date);
        let day = dates.getDay();
        let month = dates.getMonth();
        let now = new Date();
        if (dates > now && status == 0) {
          exam = "bg-gradient-info";
          return exam;
        } else if(dates == now && status == 0) {
          exam = "bg-gradient-warning";
          return exam;
        } else if(status == 1) {
          exam = "bg-gradient-success";
          return exam;
        } else {
          exam = "bg-gradient-danger"
          return exam;
        }

        
      },

      getImageComment(img) {
        // return `https://salesforce.klikpersada.co.id/ns/employee/${img}`;
        return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
      },

      getColor(date, status) {
        // let dates = new Date(date);
        // let day = dates.getDay();
        // let month = dates.getMonth();
        // let now = new Date();
        // let exam = "";
        // if(status == 1) {
        //   exam = "bg-gradient-success";
        //   return exam;
        // } else {
        //   exam = "bg-gradient-info"
        //   return exam;
        // }

        let exam = "";
        if(date == null || date == '') {
          exam = "btn-danger"
          return exam;
        }
        let dates = new Date(date);
        let day = dates.getDay();
        let month = dates.getMonth();
        let now = new Date();
        if (dates > now && status == 0) {
          exam = "bg-gradient-info";
          return exam;
        } else if(dates == now && status == 0) {
          exam = "bg-gradient-warning";
          return exam;
        } else if(dates == now) {
          exam = "bg-gradient-warning";
          return exam;
        } else if(status == 1) {
          exam = "bg-gradient-success";
          return exam;
        } else if(dates > now) {
          exam = "bg-gradient-info"
          return exam;
        } else {
          exam = "bg-gradient-danger"
          return exam;
        }
      },

      handleShowTasks(data) {
        let payload = {
          id_card: data,
          search: null,
          order: null,
        };

        this.fetchCard(payload)
          .then((response) => {
            ElNotification({
              title: "Viola!",
              message: "Success",
              type: "success",
            });
            console.log(response);
            this.showTasks = response;
            this.detail_tasks = true;
          })
          .catch((err) => {
            ElNotification({
              title: "Error!",
              message: "Error",
              type: "error",
            });
          });
      },

      getAttachment(img) {
        let gambar = "";
        if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG") {
          gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
        } else {
          gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
        }

        return gambar;
      },

      getUrl(img) {
        let gambar = "";
        if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG" || img.type == "PDF" || img.type == "DOC") {
          gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
        } else {
          gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
        }

        return gambar;
      },
      // End

      numberFormats(value) {
        if (!value) {
          return 0;
        }
        const formatOptions = {
          notation: "compact",
          compactDisplay: "short",
          minimumFractionDigits: 0,
        };
        return new Intl.NumberFormat("id-ID", formatOptions).format(value);
      },

      fetchDate() {
        if (this.date_range == null) {
          this.date_range = ["", ""];
        }

        this.start_date = this.date_range[0];
        this.end_date = this.date_range[1];

        this.fetchSoReportingData();
      },

        handleChangeStartDate() {
            if (this.date_range == null) {
                this.date_range = ["", ""];
            }
            
            this.start_date = this.date_range[0];
            this.end_date = this.date_range[1];
        },

      exportExcel() {
        const payload = {
            start_date: this.start_date,
            end_date: this.end_date,
            divisi: this.division
        };
        console.log(payload);

        this.exportEmployeeTasksReporting(payload)
            .then((response) => {
                console.log(response);
                window.open(response.url, "_blank");
            })
            .catch((err) => {
                console.log(err);
            })
      },

      getDivision(event) {
        const value = event.target.value;
        console.log(value);
        this.division = value;
        this.fetchSoReportingData();
      },

      async fetchSoReportingData() {
        this.is_loading = true;
        const payload = {
          start_date: this.start_date,
          end_date: this.end_date,
          divisi: this.division
        };

        this.doFetchReportingTasks(payload) 
            .then((response) => {
                console.log(response);
                this.is_loading = false;
                this.so = response.data;
            })
            .catch((err) => {
                this.is_loading = false;
                console.log(err);
            })
      },

      detailTicket(data, fullname) {
        this.detailTasks = data;
        this.ModalDetailTasks = true;
        this.nm_user = fullname;
      },

      detailProgress(data, fullname) {
        this.detailProcess = data;
        this.ModalDetailTasksProgress = true;
        this.nm_user = fullname;
      },

      getData(data) {
        this.detailSo = data;
        this.ModalDetailPO = true;
        console.log('data detail SO');
        console.log(this.detailSo);
      },

      periode(end_date, done_date) {
        let color = '';
        if(done_date > end_date) {
          color = 'badge-danger';
        } else {
          color = 'badge-success';
        }

        return color;
      },

      periodeProgress(end_date, done_date) {
        let color = '';
        const due = new Date(end_date);
        if(done_date > due) {
          color = 'badge-danger';
        } else {
          color = 'badge-success';
        }

        return color;
      },

      daysSinceLastDate(end_date, done_date) {
        const currentDate = new Date(end_date)
        const previousDate = new Date(done_date)
        const timeDifference = currentDate.getTime() - previousDate.getTime()
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
        // const day = timeDifference / (1000 * 60 * 60 * 24)
        const day = timeDifference / 1000 / 60 / 60 / 24
        const hour = daysDifference * 24
        const min = hour * 60
        const sec = min * 60
        // const hour = (daysDifference % 1) * 24
        // const min = (hour % 1) * 60
        // const sec = (min % 1) * 60

        if(end_date == null || done_date == null) {
          return '--';
        }

        var appndDate = Math.floor(day) + 'Hari ' + Math.floor(hour) + 'Jam ' + Math.floor(min) + 'Menit '
        return appndDate
      },

      getDaysRemaining(end_date, done_date) {
        const today = new Date(done_date);
        // start at the beginning of the day
        today.setUTCHours(0, 0, 0, 0);
        
        const targetDate = new Date(end_date);
        targetDate.setUTCHours(0, 0, 0, 0);

        // get time remaining in milliseconds
        const timeRemaining = targetDate.getTime() - today.getTime();

        /**
        * convert milliseconds to days
        * milliseconds / 1000 = seconds
        * seconds / 3600 = hours
        * hours / 24 = days
        * round up any fractional days
        */
        const timeRemainingInDays = Math.ceil(timeRemaining / (1000 * 3600 * 24));
        console.log(timeRemainingInDays);
        return timeRemainingInDays;
      },

      calculateDatePeriod(end_date, done_date) {
        // Daftar satuan waktu yang didukung: 'milliseconds', 'seconds', 'minutes', 'hours', 'days'
        const validTimeUnits = ['milliseconds', 'seconds', 'minutes', 'hours', 'days'];

        if(end_date == null || done_date == null) {
          return '--';
        }

        const currentDate = new Date(end_date)
        const previousDate = new Date(done_date)

        // Konversi kedua tanggal ke format timestamp (milisecond sejak 1 Januari 1970)
        const startTimestamp = currentDate.getTime();
        const endTimestamp = previousDate.getTime();

        // Hitung selisih antara dua tanggal dalam milisecond
        const timeDifference = endTimestamp - startTimestamp;

        // Cek Menit
        var result = Math.floor(timeDifference / (1000 * 60));

        result = Math.abs(result)

        if(result > 60) {
          result = Math.floor(timeDifference / (1000 * 60 * 60));
          result = Math.abs(result)

          if(result > 24) {
            result = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            result = Math.abs(result)
            return result + ' Hari'
          }
          return result + ' Jam';
        }

        // Cek Jam
        

        return result + ' Menit';

        // Konversi selisih milisecond ke satuan waktu yang diminta
        switch (timeUnit) {
          case 'milliseconds':
            return timeDifference;
          case 'seconds':
            return Math.floor(timeDifference / 1000);
          case 'minutes':
            return Math.floor(timeDifference / (1000 * 60));
          case 'hours':
            return Math.floor(timeDifference / (1000 * 60 * 60));
          case 'days':
            return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        }
      },
      
      calculateDatePeriodProgress(end_date, done_date) {
        // Daftar satuan waktu yang didukung: 'milliseconds', 'seconds', 'minutes', 'hours', 'days'
        const validTimeUnits = ['milliseconds', 'seconds', 'minutes', 'hours', 'days'];

        if(end_date == null || done_date == null) {
          return '--';
        }

        const currentDate = new Date(end_date)
        const previousDate = new Date(done_date)

        // Konversi kedua tanggal ke format timestamp (milisecond sejak 1 Januari 1970)
        const startTimestamp = currentDate.getTime();
        const endTimestamp = previousDate.getTime();

        // Hitung selisih antara dua tanggal dalam milisecond
        const timeDifference = endTimestamp - startTimestamp;

        // Cek Menit
        var result = Math.floor(timeDifference / (1000 * 60));

        result = Math.abs(result)

        if(result > 60) {
          result = Math.floor(timeDifference / (1000 * 60 * 60));
          result = Math.abs(result)

          if(result > 24) {
            result = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            result = Math.abs(result)
            return result + ' Hari'
          }
          return result + ' Jam';
        }

        return result + ' Menit';
      }
    },
  
    computed: {
      getBoard() {
        return this.$store.state.gopro.board;
      },
      getReporting() {
        return this.$store.state.gopro.reporting;
      },
      getDivisi() {
        return this.$store.state.hrd.divisi;
      },
    },
  };
  </script>
  