// eslint-disable-next-line no-unused-vars
import Swal from "sweetalert2";
import axios from "axios";
import { format } from "date-fns";

const axios_hrd = axios.create({
  baseURL: process.env.VUE_APP_BE_HRD,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

const axios_office = axios.create({
  baseURL: process.env.VUE_APP_OFFICE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF9zc28iOjMsImlkIjo2LCJuYW1lIjoiQWRtaW4gVHJhY2tlciIsImVtYWlsIjoiYWRtaW5AZXJha29tcC5jb20iLCJ1c2VybmFtZSI6ImFzZXAiLCJjcmVhdGVkX2F0IjoiMjAyMC0xMS0yNFQwMzoxNzowMi4wMDAwMDBaIiwidXBkYXRlZF9hdCI6IjIwMjItMTAtMDZUMDI6MTI6MzcuMDAwMDAwWiJ9.dvmzq4CRN28RTqNPD-h3sQUxPIr4G3YpzAAJFdspUdQ",
  },
});

const axios_office_excel = axios.create({
  baseURL: process.env.VUE_APP_OFFICE_URL,
  responseType: "blob",
  headers: {
    "Content-Type":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    Authorization:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF9zc28iOjMsImlkIjo2LCJuYW1lIjoiQWRtaW4gVHJhY2tlciIsImVtYWlsIjoiYWRtaW5AZXJha29tcC5jb20iLCJ1c2VybmFtZSI6ImFzZXAiLCJjcmVhdGVkX2F0IjoiMjAyMC0xMS0yNFQwMzoxNzowMi4wMDAwMDBaIiwidXBkYXRlZF9hdCI6IjIwMjItMTAtMDZUMDI6MTI6MzcuMDAwMDAwWiJ9.dvmzq4CRN28RTqNPD-h3sQUxPIr4G3YpzAAJFdspUdQ",
  },
});

const axios_sales = axios.create({
  baseURL: process.env.VUE_APP_ERAFORCE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjE0LCJpYXQiOjE2NzY4NzM4MzB9.NYxRjEAuu5avjHF1yx4Kvbc-TDOpdGrkCIn16YdlMuc",
  },
});

export default {
  namespaced: true,
  state: {
    divisi: [],
    position: [],
    employee: [],
    age_chart: null,
    gender_chart: null,
    office: [],
    summary: [],
  },
  getters: {
    getDivisiData: (state) => {
      return state.divisi;
    },
    getPositionData: (state) => {
      return state.position;
    },
    getEmployeeData: (state) => {
      return state.employee;
    },
  },
  actions: {
    fetchDivisi({ commit }, body) {
      const params = [
        `id=${body.id}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_hrd.get(`divisi?${params}`).then(
          (response) => {
            commit("SET_DIVISI", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doCreateDivisi({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_hrd.post(`divisi`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doUpdateDivisi({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_hrd.put(`divisi`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doDeleteDivisi({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_hrd.post(`divisi/delete`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchPosition({ commit }, body) {
      const params = [
        `id=${body.id}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_hrd.get(`position?${params}`).then(
          (response) => {
            commit("SET_POSITION", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doCreatePosition({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_hrd.post(`position`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doUpdatePosition({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_hrd.put(`position`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doDeletePosition({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_hrd.post(`position/delete`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchExcelEmployee({ commit }, body) {
      const params = [
        `id=${body.id}`,
        `search=${body.search}`,
        `order=${body.order}`,
        `join_start=${body.join_start}`,
        `join_end=${body.join_end}`,
        `out_start=${body.out_start}`,
        `out_end=${body.out_end}`,
        `page=${body.page}`,
        `employeement_status=${body.employeement_status}`,
        `office_location=${body.office_location}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        // {{url}}/user/report/employee?id=&search=&join_start=&join_end=&out_start=&out_end=&order=&page=1&employeement_status=ALL&office_location=all
        axios_hrd.get(`user/report/employee?${params}`).then(
          // axios_hrd.get(`user/employee?${params}`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    fetchEmployee({ commit }, body) {
      const params = [
        `id=${body.id}`,
        `search=${body.search}`,
        `order=${body.order}`,
        `join_start=${body.join_start}`,
        `join_end=${body.join_end}`,
        `out_start=${body.out_start}`,
        `out_end=${body.out_end}`,
        `page=${body.page}`,
        `employeement_status=${body.employeement_status}`,
        `office_location=${body.office_location}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_hrd.get(`user/employee?${params}`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    doUpdateEmployee({ commit }, body) {
      // body.dob = format(body.dob.toLocaleString('id-ID', { timeZone: 'Asia/Jakarta' }), "YYYY-MM-DD");
      // body.start_date = format(body.start_date.toLocaleString('id-ID', { timeZone: 'Asia/Jakarta' }), "yyyy-MM-dd HH:mm:ss");
      // body.end_date = format(body.end_date.toLocaleString('id-ID', { timeZone: 'Asia/Jakarta' }), "yyyy-MM-dd HH:mm:ss");
      return new Promise((resolve, reject) => {
        axios_hrd.put(`user/employee`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doDeleteEmployee({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_hrd.post(`user/employee/delete`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doActivationEmployee({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_hrd.post(`user/employee/activation`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    //88
    fetchAttendanceOffice({ commit }, body) {
      const params = [
        `start=${body.start}`,
        `end=${body.end}`,
        `user_id=${body.user_id}`,
        `page=${body.page}`,
        // `name=${body.name}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_office.get(`getAttendanceAll?${params}`).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchAttendanceRequestOffice({ commit }, body) {
      const params = [
        `start=${body.start}`,
        `end=${body.end}`,
        `user_id=${body.user_id}`,
        `page=${body.page}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_office.post(`admin/allRequestList`, body).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    //88
    fetchAttendanceSales({ commit }, body) {
      const params = [
        // `name=${body.name}`,
        `user_id=${body.user_id}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        //88
        
        axios_sales.patch(`check/list/hr`, body).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response);
          }
        );
      });
    },

    fetchAttendanceRequestSales({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_sales.post(`check/request/list/hr`, body).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response);
          }
        );
      });
    },

    doHandleAttendanceRequestOffice({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_office.post(`admin/v2/attendanceRequestApprove`, body).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response);
          }
        );
      });
    },

    doHandleAttendanceRequestSales({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_sales.post(`check/request/status`, body).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response);
          }
        );
      });
    },

    fetchEmployeeVisitData({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_office.post(`visitList`, body).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response);
          }
        );
      });
    },

    fetchAgeChart({ commit }) {
      return new Promise((resolve, reject) => {
        axios_hrd.get(`user/chart/age`).then(
          (response) => {
            commit("SET_AGE_CHART", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    fetchGenderChart({ commit }) {
      return new Promise((resolve, reject) => {
        axios_hrd.get(`user/chart/gender`).then(
          (response) => {
            commit("SET_GENDER_CHART", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    fetchOffice({ commit }, body) {
      const params = [
        `id=${body.id}`,
        `search=${body.search}`,
        `order=${body.order}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_hrd.get(`office?${params}`).then(
          (response) => {
            commit("SET_OFFICE", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    doCreateOffice({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_hrd.post(`office`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    doUpdateOffice({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_hrd.put(`office`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    doDeleteOffice({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_hrd.post(`office/delete`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    fetchMealAllowanceOffice({ commit }, body) {
      const params = [
        `page=${body.page}`,
        `from=${body.from}`,
        `to=${body.to}`,
        `search=${body.filter}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_office.get(`user-list-statistic?${params}`).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response);
          }
        );
      });
    },

    fetchDepartementChart({ commit }) {
      return new Promise((resolve, reject) => {
        axios_hrd.get(`user/chart/departement`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    fetchEducationChart({ commit }) {
      return new Promise((resolve, reject) => {
        axios_hrd.get(`user/chart/education`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    fetchLosChart({ commit }) {
      return new Promise((resolve, reject) => {
        axios_hrd.get(`user/chart/los`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    fetchOfficeChart({ commit }) {
      return new Promise((resolve, reject) => {
        axios_hrd.get(`user/chart/office`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    fetchUserSummary({ commit }) {
      return new Promise((resolve, reject) => {
        axios_hrd.get(`user/summary`).then(
          (response) => {
            commit("SET_SUMMARY", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            console.log(`errs ${JSON.stringify(error)}`);
            reject(error.response);
          }
        );
      });
    },

    fetchAttendanceOfficeExcel({ commit }, body) {
      const params = [
        `start=${body.start}`,
        `end=${body.end}`,
        `type=${body.type}`,
        `search=${body.search}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_office_excel.get(`exel?${params}`).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchMealAllowanceOfficeExcel({ commit }, body) {
      const params = [`start=${body.start}`, `end=${body.end}`].join("&");
      return new Promise((resolve, reject) => {
        axios_office_excel.get(`report/meal?${params}`).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchMealAllowanceSales({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_sales.post(`check/mealAllowance`, body).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchAttendanceSalesExcel({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_sales.post(`check/attendanceReport/excel`, body).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchLocationMaps({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${body.latitude},${body.longitude}&key=AIzaSyCLEKzHA-9iVyvLskye9ysvPuU-A54SaJU`
          )
          .then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              reject(error.response);
            }
          );
      });
    },
  },
  mutations: {
    SET_DIVISI(state, data) {
      state.divisi = data;
    },
    SET_POSITION(state, data) {
      state.position = data;
    },
    SET_EMPLOYEE(state, data) {
      state.employee = data;
    },
    SET_AGE_CHART(state, data) {
      state.age_chart = data;
    },
    SET_GENDER_CHART(state, data) {
      state.gender_chart = data;
    },
    SET_OFFICE(state, data) {
      state.office = data;
    },
    SET_SUMMARY(state, data) {
      state.summary = data;
    },
  },
};
