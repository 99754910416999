<template>
  <!-- <div class="card mt-3">
    <div class="card-header pb-0">
      <h6>Active Users</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="getEmployee.length == 0">
        <h6 class="text-center">No Data Found!</h6>
      </div>
      <div v-else>
        <el-table
          :data="getEmployee"
          style="width: 100%"
          height="500"
          v-loading="is_table_loading"
        >
          <el-table-column fixed prop="fullname" label="Name" />
          <el-table-column prop="nik" label="NIK" />
          <el-table-column prop="employee_no" label="ID" />
          <el-table-column label="Empl Status">
            <template #default="scope">
              <div>
                <span
                  class="badge rounded-pill bg-success"
                  v-if="scope.row.delete_date == null"
                  >Active</span
                >
                <span class="badge rounded-pill bg-danger" v-else
                  >Inactive</span
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="m-4 d-flex justify-content-center">
          <el-button> Load More </el-button>
          <el-button v-if="loading_more"> Loading ... </el-button>
        </div>
      </div>
    </div>
  </div> -->

  <div class="card mb-4 mt-3">
    <div class="card-header pb-0">
      <h6>Employee</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table
          class="table align-items-center mb-0"
          v-loading="is_table_loading"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Nik
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in getEmployee">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <img
                      src="../../../assets/img/team-2.jpg"
                      class="avatar avatar-sm me-3"
                      alt="user5"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item.fullname }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ item.email }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">{{ item.nik }}</p>
              </td>
              <td class="align-middle text-center text-sm">
                <span
                  class="badge badge-sm bg-gradient-success"
                  v-if="item.end_date == null"
                  >Active</span
                >
                <span class="badge badge-sm bg-gradient-danger" v-else
                  >No Active</span
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div class="m-4 d-flex justify-content-center">
          <el-button @click="getEmployeeDataMore"> Load More </el-button>
          <el-button v-if="loading_more"> Loading ... </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";

import { ElNotification } from "element-plus";
import { mapActions, mapMutations, useStore } from "vuex";

export default {
  name: "GoAppsAdminActiveUsers",
  data() {
    return {
      params: {
        id: "",
        search: "",
        page: 1,
        order: "ASC",
        join_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        end_date: "test",
        out_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        employeement_status: "All",
        office_location: "All",
      },
      form: {
        id: null,
        id_sso: null,
        employee_no: null,
        id_departement: null,
        nik: null,
        fullname: null,
        province: null,
        city: null,
        address: null,
        dob: null,
        gender: null,
        start_date: null,
        end_date: null,
        delete_date: null,
        image: null,
        email: null,
        username: null,
        phone: null,
        edu: null,
        id_office_location: null,
      },
      edit_dialog: false,
      is_table_loading: false,
      loading_more: false,
      is_no_more: false,
      search_divisi: "",
      divisi_data: [],
      selected_divisi: null,
      selected_office: "",
      province_data: [],
      search_province: "",
      selected_province: null,
      city_data: [],
      search_city: "",
      selected_city: null,
    };
  },
  props: {
    title: {
      type: String,
      default: "Active Users",
    },
    detail: {
      type: String,
      default: "4% more",
    },
  },

  beforeMount() {
    this.getEmployeeData();
  },

  mounted() {},
  methods: {
    ...mapActions({
      fetchEmployee: "hrd/fetchEmployee",
    }),

    ...mapMutations({
      SET_EMPLOYEE: "hrd/SET_EMPLOYEE",
    }),

    getEmployeeData() {
      this.is_table_loading = true;
      this.params.page = 1;
      const params = {
        id: this.params.id,
        search: this.params.search,
        page: this.params.page,
        order: this.params.order,
        employeement_status: this.params.employeement_status,
        office_location: this.params.office_location,
        join_start: this.params.join_date.start_date,
        join_end: this.params.join_date.end_date,
        out_start: this.params.out_date.start_date,
        out_end: this.params.out_date.end_date,
      };
      this.fetchEmployee(params)
        .then((response) => {
          this.SET_EMPLOYEE(response);
          this.is_table_loading = false;
        })
        .catch((error) => {
          this.is_no_more = true;
          this.is_table_loading = false;
          this.SET_EMPLOYEE([]);
        });
    },

    getEmployeeDataMore() {
      this.loading_more = true;
      const page = 1;
      this.params.page += page;
      const params = {
        id: this.params.id,
        search: this.params.search,
        page: this.params.page,
        order: this.params.order,
        join_start: this.params.join_date.start_date,
        join_end: this.params.join_date.end_date,
        out_start: this.params.out_date.start_date,
        out_end: this.params.out_date.end_date,
        employeement_status: this.params.employeement_status,
        office_location: this.params.office_location,
      };
      this.fetchEmployee(params)
        .then((response) => {
          if (response.length < 10) {
            this.is_no_more = true;
          } else {
            this.is_no_more = false;
          }
          let result = this.getEmployee;
          const merged = [...result, ...response];
          console.log(merged);
          this.SET_EMPLOYEE(merged);
          this.loading_more = false;
        })
        .catch((error) => {
          this.is_no_more = true;
          this.loading_more = false;
        });
    },
  },

  computed: {
    getEmployee() {
      return this.$store.state.hrd.employee;
    },
  },
};
</script>
