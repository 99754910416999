<template>
  <div class="mx-4">
    <div class="card p-3 mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <span class="text-bold">Attendance</span>
        </div>
        <div>
          <el-popover
            placement="bottom-end"
            title="Tips"
            :width="300"
            trigger="hover"
            :content="pop_over_content"
          >
            <template #reference>
              <i class="fas fa-info-circle cursor-pointer"></i>
            </template>
          </el-popover>
        </div>
      </div>
      <div class="d-flex justify-content-evenly align-items-center">
        <div class="d-flex flex-column">
          <div class="text-center">Filter Date</div>
          <el-date-picker
            v-model="params.date_range"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            size="medium"
            value-format="YYYY-MM-DD"
            @change="handleChangeFilterDate"
          />
        </div>
        <div class="d-flex flex-column">
          <argon-button
            class="mb-1"
            variant="gradient"
            color="info"
            size="sm"
            @click="handleExportOffice"
            :disabled="downloading_office"
          >
            <div>Export Office</div>
          </argon-button>
          <argon-button
            variant="gradient"
            color="info"
            size="sm"
            @click="handleExportSales"
            :disabled="downloading_sales"
          >
            <div>Export Sales</div>
          </argon-button>
        </div>
      </div>

      <!-- 88 -->
      <div class="card p-3 mb-3">
        <div class="row">
          <!-- <div class="col-md-8 col-sm-12">
            
              <el-input
              v-model="params.name"
              class="w-100 m-2"
              size="md"
              placeholder="Search by employee name"
              :prefix-icon="Search"
              @change="handleChangeFilterDate"
            />
          </div> -->
        </div>
      </div>

    </div>

    <div
      class="card mb-3"
      v-if="
        !is_click_load_more &&
        (is_table_loading_office || is_table_loading_sales)
      "
    >
      <span class="text-center">Loading...</span>
    </div>

    
    <div class="card mb-3" v-else>
      <div v-if="attendance_data.length != 0">
        <!-- <el-scrollbar height="500px" always>
          <ul class="list-group" :class="''">
            <li v-for="(data, index) in attendance_data" :key="index">
              <div
                class="mb-2 border-0 list-group-item border-radius-lg m-3"
                :class="'ps-0'"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div
                    class="text-center shadow icon icon-shape icon-sm bg-gradient-dark"
                    :class="'me-3'"
                  >
                    <i
                      :class="
                        data.departement == 'Sales'
                          ? 'fas fa-money-bill-wave'
                          : 'fas fa-laptop-house'
                      "
                      class="text-white opacity-10"
                    ></i>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="mb-1 text-sm text-dark">
                      {{ data.name }}
                    </h6>
                    <span class="text-xs">
                      Date:
                      <span class="font-weight-bold">
                        {{ data.date }}
                      </span>
                    </span>
                  </div>
                  <div class="mx-3">|</div>
                  <div class="d-flex flex-column">
                    <h6 class="text-sm text-dark text-center">Time</h6>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="me-2">
                        <span class="text-xs">
                          IN:
                          <span class="font-weight-bold">
                            {{ data.time_in }}
                          </span>
                        </span>
                      </div>
                      <div>
                        <span class="text-xs">
                          OUT:
                          <span class="font-weight-bold">
                            {{ data.time_out }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mx-3">|</div>
                  <div class="d-flex flex-column">
                    <h6 class="text-sm text-dark text-center">Location In</h6>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <span
                        class="cursor-pointer badge rounded-pill bg-success"
                        v-if="data.is_match_in"
                        @click="goToMap(data.lat_in, data.long_in)"
                        >Match</span
                      >
                      <span
                        class="cursor-pointer badge rounded-pill bg-danger"
                        v-else
                        @click="goToMap(data.lat_in, data.long_in)"
                        >Not-Match</span
                      >
                    </div>
                  </div>
                  <div class="mx-3">|</div>
                  <div class="d-flex flex-column">
                    <h6 class="text-sm text-dark text-center">Location Out</h6>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-if="data.is_match_out != null"
                    >
                      <span
                        class="cursor-pointer badge rounded-pill bg-success"
                        v-if="data.is_match_out"
                        @click="goToMap(data.lat_in, data.long_in)"
                        >Match</span
                      >
                      <span
                        class="cursor-pointer badge rounded-pill bg-danger"
                        v-else
                        @click="goToMap(data.lat_in, data.long_in)"
                        >Not-Match</span
                      >
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-else
                    >
                      -
                    </div>
                  </div>
                  <div class="mx-3">|</div>
                  <div class="d-flex flex-column">
                    <h6 class="text-sm text-dark text-center">Late In</h6>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-if="data.is_late != null"
                    >
                      <span
                        class="badge rounded-pill bg-danger"
                        v-if="data.is_late == 1"
                        >Late</span
                      >
                      <span class="badge rounded-pill bg-success" v-else
                        >OK</span
                      >
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-else
                    >
                      -
                    </div>
                  </div>
                </div>
              </div>
              <hr class="horizontal dark" />
            </li>
          </ul>
        </el-scrollbar> -->
        <!-- <div
          class="m-4 d-flex justify-content-center"
          v-if="is_table_loading_office"
        >
          <span> Loading data non-sales... </span>
        </div>
        <div
          class="m-4 d-flex justify-content-center"
          v-if="is_table_loading_sales"
        >
          <span> Loading data sales... </span>
        </div> -->
        <!-- <div class="m-4 d-flex justify-content-center">
          <el-button
            v-if="!loading_more && !is_no_more && !is_table_loading"
            @click="getAttendanceDataMore"
          >
            Load More
          </el-button>
          <el-button v-if="loading_more"> Loading ... </el-button>
        </div> -->
      </div>
      <!-- <div v-else>
        <span class="d-flex justify-content-center"> No Data </span>
      </div> -->
    </div>
  </div>

  <div class="card p-3">
      <div v-if="attendance_data.length == 0">
        <h6 class="text-center">No Data Found!</h6>
      </div>
      <div v-else>
        <el-table :data="attendance_data" style="width: 100%" height="500">
          <el-table-column fixed type="index" label="No" width="60"/>
          <el-table-column label="Type" sortable>
          <template #default="scope">
            <div
            class="text-center shadow icon icon-shape icon-sm bg-gradient-dark"
            :class="'me-3'"
            >
            <i
              :class="
                scope.row.departement === 'Sales'
                  ? 'fas fa-money-bill-wave'
                  : 'fas fa-laptop-house'
              "
              class="text-white opacity-10"
            ></i>
          </div>
          </template>
          </el-table-column>
          <el-table-column prop="name" label="Name" sortable/>
          <el-table-column prop="date" label="Date" sortable/>
          <!-- 88 -->
          <el-table-column
            prop="time_in"
            label="Time In"
            width="100"
            sortable
          >
            <template #default="scope">
              <el-tag
                :type="scope.row.is_late === 0 ? 'success' : 'danger'"
                disable-transitions
                >{{ scope.row.time_in }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column prop="time_out" label="Time Out" sortable/>
          
          <el-table-column label="Location In" sortable>
            <template #default="scope">
              <div>
                <span
                  class="badge rounded-pill bg-success"
                  v-if="scope.row.is_match_in"
                  @click="goToMap(scope.row.lat_in, scope.row.long_in)"
                  >Match</span
                >
                <span class="badge rounded-pill bg-danger"
                  v-else
                  @click="goToMap(scope.row.lat_in, scope.row.long_in)"
                  >Not-Match</span
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Location Out" sortable>
            <template #default="scope">
              <div>
                <span
                  class="badge rounded-pill bg-success"
                  v-if="scope.row.is_match_out"
                  @click="goToMap(scope.row.lat_in, scope.row.long_in)"
                  >Match</span
                >
                <span class="badge rounded-pill bg-danger"
                  v-else-if="scope.row.is_match_out ==0"
                  @click="goToMap(scope.row.lat_in, scope.row.long_in)"
                  >Not-Match</span
                >
                <span class="badge rounded-pill bg-danger"
                  v-else-if="scope.row.is_match_out == null"  
                  ></span
                >
              </div>
            </template>
          </el-table-column>


          <!-- <el-table-column label="Late In" sortable>
            <template #default="scope">
              <div>
                <span
                  class="badge rounded-pill bg-success"
                  v-if="scope.row.is_late == 0"
                  >OK</span
                >
                <span class="badge rounded-pill bg-danger"
                  v-else-if="scope.row.is_late == 1"
                  >Late</span
                >
              </div>
            </template>
          </el-table-column> -->
        </el-table>

        <div class="m-4 d-flex justify-content-center">
          <el-button
            v-if="!loading_more && !is_no_more && !is_table_loading"
            @click="getAttendanceDataMore"
          >
            Load More
          </el-button>
          <el-button v-if="loading_more"> Loading ... </el-button>
        </div>
      </div>
    </div>

</template>

<script>
import { format } from "date-fns";
import { mapActions } from "vuex";

import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "GoappsAdminAttendance",

  components: {
    ArgonButton,
  },

  data() {
    return {
      params: {
        date_range: [
          format(new Date(), "yyyy-MM-dd 00:00:00"),
          format(new Date(), "yyyy-MM-dd 23:59:59"),
        ],
        start_date: format(new Date(), "yyyy-MM-dd 00:00:00"),
        end_date: format(new Date(), "yyyy-MM-dd 23:59:59"),
        id_user: "",
        filter: "",
        page: 1,
        name: "",
      },
      attendance_sales_data: [],
      attendance_data: [],
      attendance_office: [],
      attendance_sales: [],
      limit: 5,
      start: 0,
      end: 0,
      pop_over_content:
        "This is contain list attendance of sales and non-sales",
      is_table_loading_office: false,
      loading_more_office: false,
      is_no_more_office: false,
      is_table_loading_sales: false,
      loading_more_sales: false,
      is_no_more_sales: false,
      is_click_load_more: false,
      downloading_sales: false,
      downloading_office: false,
    };
  },

  beforeMount() {
    this.getAttendanceOffice();
    this.getAttendanceSales();
  },

  mounted() {
    // this.getAttendanceData();
  },

  methods: {
    ...mapActions({
      fetchAttendanceOffice: "hrd/fetchAttendanceOffice",
      fetchAttendanceSales: "hrd/fetchAttendanceSales",
      fetchAttendanceOfficeExcel: "hrd/fetchAttendanceOfficeExcel",
      fetchAttendanceSalesExcel: "hrd/fetchAttendanceSalesExcel",
    }),

    goToMap(lat, long) {
      window.open(`https://maps.google.com/?q=${lat},${long}`, "_blank");
    },

    getAttendanceOffice() {
      this.is_table_loading_office = true;
      const start = this.params.start_date.split(" ")[0];
      const end = this.params.end_date.split(" ")[0];
      const params = {
        start: start,
        end: end,
        user_id: this.params.id_user,
        page: this.params.page,
        name: this.params.name,
      };
      // console.log("ini params: ", params);
      this.fetchAttendanceOffice(params)
        .then((response) => {
          if (response.data.length < 5) {
            this.is_no_more_office = true;
          } else {
            this.is_no_more_office = false;
          }
          let new_data = [];
          response.data.forEach((data) => {
            const date = format(new Date(), "yyyy-MM-dd");
            let time_in = data.time_in;

            let date2_in = new Date(date + " " + time_in);
            let date1_in = new Date(date + " " + "08:00:00");
            const checkinDiffTime = date2_in - date1_in;
            const checkinDiffHours = Math.ceil(
              checkinDiffTime / (1000 * 60 * 60)
            );

            let is_late = 0;
            if (checkinDiffHours > 0) {
              is_late = 1;
            }

            const payload = {
              name: data.user.sales_name,
              date: data.attendance_date,
              time_in: data.time_in,
              time_out: data.time_out,
              lat_in: data.latitude_in,
              long_in: data.longitude_in,
              lat_out: data.latitude_out,
              long_out: data.longitude_out,
              is_match_in: data.is_match_in,
              is_match_out: data.is_match_out,
              is_late: is_late,
              departement: "Non-Sales",
            };
            new_data.push(payload);
          });
          const old_data = this.attendance_data;
          this.attendance_office = new_data;
          this.attendance_data = [...old_data, ...new_data];
          this.is_table_loading_office = false;
          this.is_click_load_more = false;
          console.log("ini att: ", this.attendance_data);
        })
        .catch((error) => {
          this.is_click_load_more = false;
          this.is_table_loading_office = false;
          this.is_no_more_office = true;
          this.attendance_office.push([]);

          console.log(`err office ${JSON.stringify(error)}`);
        });
    },

    getAttendanceSales() {
      this.is_table_loading_sales = true;
      const start = this.params.start_date;
      const end = this.params.end_date;
      const params = {
        first_date: start,
        last_date: end,
        user_id: this.params.id_user,
        filter: this.params.filter,
        name: this.params.name,
      };
      this.fetchAttendanceSales(params)
        .then((response) => {
          response.result.forEach((data) => {
            const index = data.details.length - 1;

            let is_match_in = 0;
            if (
              data.details[index].dist_kokas <= 150 ||
              data.details[index].dist_alaydrus <= 150
            ) {
              is_match_in = 1;
            }

            const date = format(new Date(), "yyyy-MM-dd");
            let time_in = data.details[index].time_in;

            let date2_in = new Date(time_in);
            let date1_in = new Date(date + " " + "08:00:00");
            const checkinDiffTime = date2_in - date1_in;
            const checkinDiffHours = Math.ceil(
              checkinDiffTime / (1000 * 60 * 60)
            );

            let is_late = 0;
            if (checkinDiffHours > 0) {
              is_late = 1;
            }

            const payload = {
              name: data.name,
              date: data.details[index].time_in.split(" ")[0],
              time_in: data.details[index].time_in.split(" ")[1],
              time_out:
                data.details[index].time_out != null
                  ? data.details[index].time_out.split(" ")[1]
                  : null,
              lat_in: data.details[index].latitude_in,
              long_in: data.details[index].longitude_in,
              lat_out: data.details[index].latitude_out,
              long_out: data.details[index].longitude_out,
              is_match_in: is_match_in,
              is_match_out: null,
              is_late: is_late,
              departement: "Sales",
            };
            this.attendance_sales_data.push(payload);
          });
          this.attendance_sales = this.attendance_sales_data.slice();
          this.start = (this.params.page - 1) * this.limit;
          this.end = this.params.page * this.limit;
          const sales_data = this.attendance_sales.splice(this.start, this.end);
          const old_data = this.attendance_data;
          this.attendance_data = [...old_data, ...sales_data];
          this.is_table_loading_sales = false;
          this.is_click_load_more = false;

          console.log("ini data :", this.attendance_sales_data);
        })
        .catch((error) => {
          this.attendance_sales.push([]);
          this.is_click_load_more = false;
          this.is_table_loading_sales = false;
          console.log(`err sales ${JSON.stringify(error)}`);
        });
    },

    getAttendanceSalesMore() {
      this.is_table_loading_sales = true;
      const length = this.attendance_sales_data.length;
      const slice = this.attendance_sales_data.slice();
      this.start = (this.params.page - 1) * this.limit;
      this.start += 1;
      console.log(`start ${this.start}`);
      this.end = this.params.page * this.limit;
      console.log(`end ${this.end}`);
      console.log(`frist length ${length}`);
      if (this.end <= length) {
        console.log(`ok ${this.start}, ${this.end}`);
        const sales_data = slice.splice(this.start, this.limit);
        console.log(`ok length ${sales_data.length}`);
        const old_data = this.attendance_data;
        this.attendance_data = [...old_data, ...sales_data];
      }
      this.is_table_loading_sales = false;
    },

    getAttendanceDataMore() {
      // console.log("ini name: ");
      this.is_click_load_more = true;
      this.params.page += 1;
      // const params = {
      //   name: this.params.name,
      // };
      this.getAttendanceOffice();
      this.getAttendanceSalesMore();
      // console.log(`sales ${this.attendance_sales.length}`);
      // const sales_data = this.attendance_sales.splice(this.start, this.end);
      // console.log(`sales ${JSON.stringify(sales_data)}`);
    },

    handleChangeFilterDate() {
      //88
      const start = this.params.date_range[0] + " 00:00:00";
      const end = this.params.date_range[1] + " 23:59:59";
      this.params.start_date = format(new Date(start), "yyyy-MM-dd 00:00:00");
      this.params.end_date = format(new Date(end), "yyyy-MM-dd 23:59:59");
      this.params.page = 1;
      this.attendance_sales_data = [];
      this.attendance_data = [];
      this.attendance_office = [];
      this.attendance_sales = [];
      this.start = 0;
      this.end = 0;
      // this.params.name = "";
      this.getAttendanceOffice();
      this.getAttendanceSales();
    },

    handleExportOffice() {
      const start = format(new Date(this.params.start_date), "dd-MMM-yyyy");
      const end = format(new Date(this.params.end_date), "dd-MMM-yyyy");
      const format_name = `AttendanceOffice_${start}_${end}`;
      this.downloading_office = true;
      ElMessage({
        message: "Please wait downloading office attendance report..",
        type: "info",
        duration: 2000,
      });
      const params = {
        start: this.params.start_date.split(" ")[0],
        end: this.params.end_date.split(" ")[0],
        type: "detail",
        search: this.params.filter,
      };
      this.fetchAttendanceOfficeExcel(params)
        .then((response) => {
          this.downloading_office = false;
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${format_name}.xlsx`);
          document.body.appendChild(link);
          link.click();
          ElNotification({
            title: "Voila!",
            message: "Success download office attendance report!",
            type: "success",
          });
        })
        .catch((error) => {
          this.downloading_office = false;
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleExportSales() {
      this.downloading_sales = true;
      ElMessage({
        message: "Please wait downloading sales attendance report..",
        type: "info",
        duration: 2000,
      });
      const params = {
        start_date: this.params.start_date,
        end_date: this.params.end_date,
      };
      this.fetchAttendanceSalesExcel(params)
        .then((response) => {
          this.downloading_sales = false;
          window.open(response.result, "_blank");
          ElNotification({
            title: "Voila!",
            message: "Success download sales attendance report!",
            type: "success",
          });
        })
        .catch((error) => {
          this.downloading_sales = false;
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },
  },

  computed: {},
};
</script>
