import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import axios from 'axios';
export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      notification: [],
      detailMember: null,
      detail_card: false,
      detail_tasks: false,
      showTasks: {}
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  mounted() {
    this.fetchNotificationData();
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator", "SET_APP_PAGE_NAME"]),
    ...mapActions(["toggleSidebarColor"], {
      fetchNotification: "gopro/fetchNotification",
      fetchCard: "gopro/fetchCard"
    }),
    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    fetchNotificationData() {
      axios.get("https://goopro.erakomp.co.id/api/notifications", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      }).then(results => {
        this.notification = results.data.data;
        console.log('SUCCESS');
      }).catch(error => {
        console.log('ERROR');
        console.log(error);
      });
    },
    goToHome() {
      this.SET_APP_PAGE_NAME("");
      this.$router.push({
        name: "Permisson"
      });
      const routeArr = this.$route.path.split("/");
      if (routeArr[1] == "2") {
        this.$store.dispatch("eraforce/removeEraforceData");
      }
    },
    timeAgo(date) {
      date = new Date(date);
      const seconds = Math.floor((new Date() - date) / 1000);
      let interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return interval + ' years ago';
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + ' months ago';
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + ' days ago';
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + ' hours ago';
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + ' minutes ago';
      }
      if (seconds < 10) return 'just now';
      return Math.floor(seconds) + ' seconds ago';
    },
    getDate(date) {
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let dates = new Date(date);
      let day = dates.getDate();
      let month = dates.getMonth();
      var year = dates.getFullYear();

      // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
      let now = new Date();
      let exam = "";
      if (dates > now) {
        exam = "on going";
      } else {
        exam = "over due";
      }
      let result = day + "/" + monthNames[month] + "/" + year;
      return result;
    },
    getDateDue(date) {
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let dates = new Date(date);
      let day = dates.getDate();
      let month = dates.getMonth();
      var year = dates.getFullYear();
      const minute = 1000 * 60;
      const hour = minute * 60;
      const days = hour * 24;
      const years = days * 365;
      let time = dates.getHours() + ":" + dates.getMinutes();
      let hasil = Math.round(dates.getTime() / year);

      // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
      let now = new Date();
      let exam = "";
      if (dates > now) {
        exam = "on going";
      } else {
        exam = "over due";
      }
      let result = day + "/" + monthNames[month] + "/" + year + " : " + time;
      return result;
    },
    getColorDueDate(date, status) {
      let exam = "";
      if (date == null || date == '') {
        exam = "btn-danger";
        return exam;
      }
      let dates = new Date(date);
      let day = dates.getDay();
      let month = dates.getMonth();
      let now = new Date();
      if (dates > now && status == 0) {
        exam = "bg-gradient-info";
        return exam;
      } else if (dates == now && status == 0) {
        exam = "bg-gradient-warning";
        return exam;
      } else if (status == 1) {
        exam = "bg-gradient-success";
        return exam;
      } else {
        exam = "bg-gradient-danger";
        return exam;
      }
    },
    getImageComment(img) {
      // return `https://salesforce.klikpersada.co.id/ns/employee/${img}`;
      return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
    },
    getColor(date, status) {
      // let dates = new Date(date);
      // let day = dates.getDay();
      // let month = dates.getMonth();
      // let now = new Date();
      // let exam = "";
      // if(status == 1) {
      //   exam = "bg-gradient-success";
      //   return exam;
      // } else {
      //   exam = "bg-gradient-info"
      //   return exam;
      // }

      let exam = "";
      if (date == null || date == '') {
        exam = "btn-danger";
        return exam;
      }
      let dates = new Date(date);
      let day = dates.getDay();
      let month = dates.getMonth();
      let now = new Date();
      if (dates > now && status == 0) {
        exam = "bg-gradient-info";
        return exam;
      } else if (dates == now && status == 0) {
        exam = "bg-gradient-warning";
        return exam;
      } else if (dates == now) {
        exam = "bg-gradient-warning";
        return exam;
      } else if (status == 1) {
        exam = "bg-gradient-success";
        return exam;
      } else if (dates > now) {
        exam = "bg-gradient-info";
        return exam;
      } else {
        exam = "bg-gradient-danger";
        return exam;
      }
    },
    handleShowTasks(data) {
      let payload = {
        id_card: data,
        search: null,
        order: null
      };
      const params = [`id_card=${data}`, `search=`, `order=`].join("&");
      axios.get(`https://goopro.erakomp.co.id/api/card?${params}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      }).then(response => {
        ElNotification({
          title: "Viola!",
          message: "Success",
          type: "success"
        });
        this.showTasks = response.data.data;
        this.detail_tasks = true;
      }).catch(err => {
        ElNotification({
          title: "Error!",
          message: "Tasks not found",
          type: "error"
        });
      });

      // this.fetchCard(payload)
    },

    getAttachment(img) {
      let gambar = "";
      if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG") {
        gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
      } else {
        gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
      }
      return gambar;
    },
    getUrl(img) {
      let gambar = "";
      if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG" || img.type == "PDF" || img.type == "DOC") {
        gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
      } else {
        gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
      }
      return gambar;
    },
    // End

    goToProfile() {
      return;
      this.SET_APP_PAGE_NAME("");
      this.$router.push({
        name: "ProfileNonSales"
      });
    },
    imageLoadError() {
      return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
    }
  },
  components: {
    Breadcrumbs
  },
  computed: {
    currentRouteName() {
      return this.$store.state.dashboard_app_title;
    },
    currentSubRouteName() {
      var _this$$store$state$da;
      return (_this$$store$state$da = this.$store.state.dashboard_app_sub_title) !== null && _this$$store$state$da !== void 0 ? _this$$store$state$da : "none";
    },
    getUserLoginData() {
      return this.$store.state.auth.login_data;
    },
    getUserData() {
      return this.$store.state.eraforce.user_data;
    }
  }
};