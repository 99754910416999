<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.money.title"
              :value="countData.countBoard"
              :iconClass="stats.money.iconClass"
              :iconBackground="stats.money.iconBackground"
              directionReverse
            >
            </card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.users.title"
              :value="countData.countTasks"
              :iconClass="stats.users.iconClass"
              :iconBackground="stats.users.iconBackground"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.clients.title"
              :value="countData.countKanban"
              :iconClass="stats.clients.iconClass"
              :iconBackground="stats.clients.iconBackground"
              :percentageColor="stats.clients.percentageColor"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.sales.title"
              :value="countData.countOverdue"
              :iconClass="stats.sales.iconClass"
              :iconBackground="stats.sales.iconBackground"
              directionReverse
            ></card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <!-- <gradient-line-chart /> -->
              <TaskChart />
            </div>

            <div>
              <active-users />
            </div>
          </div>
          <div class="col-lg-5">
            <!-- <carousel /> -->
            <div class="card">
              <div class="card-header mx-4 p-3 text-center">
                <div
                  class="icon icon-shape icon-lg bg-gradient-primary shadow text-center border-radius-lg"
                >
                  <i class="fas fa-user-clock opacity-10"></i>
                </div>
              </div>
              <div class="card-body pt-0 p-3 text-center">
                <h6 class="text-center mb-0">Status Summary</h6>
                <span class="text-xs">Closed Value</span>
                <hr class="horizontal dark my-3" />
                <h5 class="mb-0">{{ countData.countSummary }}</h5>
              </div>
            </div>

            <categories-card />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/examples/Cards/Card.vue";
import GradientLineChart from "./components/chartTaskLineCard.vue";
import Carousel from "../components/Carousel.vue";
import CategoriesCard from "./components/totalUsers.vue";
import ActiveUsers from "./components/activeUsers.vue";

import TaskChart from "@/components/GooPro/TasksChart.vue";

import { mapActions, useStore } from "vuex";
import ArgonButton from "@/components/ArgonButton.vue";
import { ElNotification } from "element-plus";

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";

export default {
  name: "dashboard-default",
  data() {
    return {
      totalProject: 6,
      countData: {},
      dashboard: {},
      resultSumProject: [],
      params: {
        id_board: "",
        search: "",
        order: "asc",
      },
      paramsCard: {
        id_card: "",
        search: "",
        order: "asc",
      },
      is_table_loading: false,
      stats: {
        money: {
          title: "Total Project",
          value: "6",
          percentage: "+55%",
          iconClass: "fas fa-project-diagram",
          detail: "update yesterday",
          iconBackground: "bg-gradient-primary",
        },
        users: {
          title: "Total Task",
          value: "5",
          percentage: "+3%",
          iconClass: "fas fa-tasks",
          iconBackground: "bg-gradient-danger",
          detail: "update yesterday",
        },
        clients: {
          title: "Total List",
          value: "0",
          percentage: "-2%",
          iconClass: "fas fa-clipboard-check",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-success",
          detail: "update yesterday",
        },
        sales: {
          title: "Over due task",
          value: "0",
          percentage: "+5%",
          iconClass: "fas fa-times-circle",
          iconBackground: "bg-gradient-warning",
          detail: "update yesterday",
        },
      },
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },

  beforeMount() {
    // this.fetchBoardData();
    // this.fetchCardData();
    this.fetchDashboardData();
  },

  mounted() {},

  methods: {
    ...mapActions({
      fetchBoard: "gopro/fetchBoard",

      // Kanban
      fetchKanban: "gopro/fetchKanban",
      fetchCard: "gopro/fetchCard",

      // Dashboard
      fetchDashboard: "gopro/fetchDashboard",
    }),

    fetchBoardData() {
      console.log("ini cella");
      this.is_table_loading = true;
      const payload = {
        id_board: this.params.id_board,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchBoard(payload).then((response) => {
        this.is_table_loading = false;
        this.resultSumProject = response.data;
      });
    },

    fetchCardData() {
      const payload = {
        id_card: this.paramsCard.id_card,
        search: this.paramsCard.search,
        order: this.paramsCard.order,
      };
      this.fetchCard(payload).then((response) => {
        console.log("Hello World");
      });
    },

    fetchDashboardData() {
      this.fetchDashboard().then((response) => {
        this.countData = response;
        console.log(this.countData);
      });
    },
  },

  components: {
    // getBoard() {
    //   return this.$store.state.gopro.board;
    // },
    // getCard() {
    //   return this.$store.state.gopro.card;
    // },
    // getDashboard() {
    //   return this.$store.state.gopro.dashboard;
    // },
    Card,
    GradientLineChart,
    Carousel,
    CategoriesCard,
    ActiveUsers,
    TaskChart,
  },
};
</script>
