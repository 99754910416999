<template>
  <div>
    <div>
      <div class="card p-3">
        <argon-input
          type="text"
          placeholder="Search employee..."
          name="employee"
          size="lg"
          fullWidth
          :value="search"
          v-model="search"
          @change="getAllEmployee"
        />
        <div class="mt-2">
          <argon-button
            variant="gradient"
            color="info"
            size="sm"
            @click="openAddApp"
          >
            <div><i class="fa fa-plus"> </i> Add New Apps</div>
          </argon-button>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div class="card p-3">
        <el-table
          :data="userData"
          height="400"
          style="width: 100%"
          v-loading="loading_data"
        >
          <el-table-column prop="employee_no" label="Employee ID" />
          <el-table-column prop="fullname" label="Employee Name" />
          <el-table-column prop="departement" label="Departement" />
          <el-table-column prop="gender" label="Gender" />
          <el-table-column prop="start_date" label="Start Date" />
          <el-table-column prop="end_date" label="Status" />
          <el-table-column label="Actions">
            <template #default="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <el-icon class="el-icon--right">
                    <arrow-down />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="handleOpenApp(scope.row)"
                      >Apps</el-dropdown-item
                    >
                    <el-dropdown-item @click="handleOpenMenu(scope.row)"
                      >Menu</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- Add Dialog -->
    <el-dialog
      v-model="create_app_dialog"
      title="Create New App"
      width="40%"
      :show-close="false"
    >
      <div>
        <label for="">Apps Name</label>
        <argon-input
          type="text"
          placeholder="Input Apps Name"
          name="apps"
          size="lg"
          fullWidth
          :value="apps"
          v-model="apps"
        />
      </div>
      <argon-button
        class="mt-4"
        variant="gradient"
        color="success"
        fullWidth
        size="lg"
        @click="handleCreateApp"
        :disabled="apps == null || apps == ''"
      >
        <div>Save</div>
      </argon-button>
      <argon-button
        class="mt-2"
        variant="gradient"
        color="danger"
        fullWidth
        size="lg"
        @click="handleCancelName"
      >
        <div>Cancel</div>
      </argon-button>
    </el-dialog>

    <!-- Edit Dialog -->
    <el-dialog
      v-model="dialogVisible"
      title="Change App's Name"
      width="40%"
      :show-close="false"
    >
      <div>
        <label for="">Apps Name</label>
        <argon-input
          type="text"
          placeholder="Input Apps Name"
          name="apps"
          size="lg"
          fullWidth
          :value="apps"
          v-model="apps"
        />
      </div>
      <argon-button
        class="mt-4"
        variant="gradient"
        color="success"
        fullWidth
        size="lg"
        @click="handleUpdateApp"
        :disabled="apps == null || apps == ''"
      >
        <div>Save</div>
      </argon-button>
      <argon-button
        class="mt-2"
        variant="gradient"
        color="danger"
        fullWidth
        size="lg"
        @click="handleCancelName"
      >
        <div>Cancel</div>
      </argon-button>
    </el-dialog>

    <!-- Apps -->
    <el-dialog
      v-model="add_apps_dialog"
      title="Add App's Permission"
      width="40%"
      :show-close="false"
    >
      <div>
        <el-select
          v-model="apps_permission"
          multiple
          placeholder="Select Apps"
          style="width: 100%"
        >
          <el-option
            v-for="item in appsData"
            :key="item.id_apps"
            :label="item.apps"
            :value="item.id_apps"
          />
        </el-select>
      </div>
      <argon-button
        class="mt-4"
        variant="gradient"
        color="success"
        fullWidth
        size="lg"
        @click="handleAddAppPermission"
        :disabled="apps_permission.length == 0"
      >
        <div>Save</div>
      </argon-button>
      <argon-button
        class="mt-2"
        variant="gradient"
        color="danger"
        fullWidth
        size="lg"
        @click="cancelOpenApp"
      >
        <div>Cancel</div>
      </argon-button>
    </el-dialog>

    <!-- Menu -->
    <el-dialog
      v-model="add_menu_dialog"
      title="Add Apps's Menu Permission"
      width="50%"
      :show-close="false"
    >
      <div>
        <div class="mb-3">
          <el-select
            v-model="selected_user_app"
            placeholder="Select"
            style="width: 100%"
            @change="handleChangeApp"
          >
            <el-option
              v-for="item in apps_permission"
              :key="item.id_apps"
              :label="item.apps"
              :value="item"
            />
          </el-select>
        </div>

        <div v-if="selected_user_app != null">
          <span class="text-center" v-if="is_loading_menu_data">
            <h6>Please wait...</h6>
          </span>
          <el-transfer
            v-else
            v-model="selected_menu_apps"
            :props="{
              key: 'id',
              label: 'menu',
            }"
            :titles="['Source', 'Target']"
            :data="list_app_menu"
            style="width: 100%"
          />
        </div>
      </div>
      <argon-button
        class="mt-4"
        variant="gradient"
        color="success"
        fullWidth
        size="lg"
        @click="handleUpdateMenu"
        :disabled="selected_menu_apps.length == 0"
      >
        <div>Save</div>
      </argon-button>
      <argon-button
        class="mt-2"
        variant="gradient"
        color="danger"
        fullWidth
        size="lg"
        @click="handleCancelUpdateMenu"
      >
        <div>Cancel</div>
      </argon-button>
    </el-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { mapActions } from "vuex";

import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  data() {
    return {
      search: "",
      dialogVisible: false,
      apps: null,
      selected_apps: null,
      selected_user: null,
      create_app_dialog: false,
      loading_data: false,
      apps_permission: [],
      old_apps_permission: [],
      add_apps_dialog: false,
      user_apps_permission: [],
      add_menu_dialog: false,
      selected_user_app: null,
      list_app_menu: [],
      selected_menu_apps: [],
      is_loading_menu_data: false,
    };
  },

  components: {
    ArgonInput,
    ArgonButton,
  },

  mounted() {
    this.getAllEmployee();
    this.getAllApps();
  },

  methods: {
    ...mapActions({
      fetchUser: "administrator/fetchUser",
      fetchApps: "administrator/fetchApps",
      doCreateApp: "administrator/doCreateApp",
      doUpdateApp: "administrator/doUpdateApp",
      doDeleteApp: "administrator/doDeleteApp",
      doAddAppPermission: "administrator/doAddAppPermission",
      fetchUserApps: "administrator/fetchUserApps",
      doRevokeAppPermission: "administrator/doRevokeAppPermission",
      fetchUserMenuPermission: "administrator/fetchUserMenuPermission",
      fetchMenuByApps: "administrator/fetchMenuByApps",
      doAddMenuPermission: "administrator/doAddMenuPermission",
      doRevokePrevMenuPermission: "administrator/doRevokePrevMenuPermission",
    }),

    getAllEmployee() {
      this.loading_data = true;
      const payload = {
        search: this.search,
      };
      this.fetchUser(payload).then((response) => {
        this.loading_data = false;
        console.log(`halo`);
      });
    },

    getAllApps() {
      const payload = {
        search: "",
        id_user: "",
      };
      this.fetchApps(payload);
    },

    openAddApp() {
      this.create_app_dialog = true;
    },

    handleCancelName() {
      this.apps = null;
      this.dialogVisible = false;
    },

    handleEdit(scope) {
      this.selected_apps = scope;
      this.apps = scope.apps;
      this.dialogVisible = true;
    },

    handleCreateApp() {
      this.create_app_dialog = false;
      if (this.apps == null || this.apps == "") {
        return Swal.fire({
          icon: "error",
          title: `Oopss!`,
          text: `Error: Apps name is required!`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.create_app_dialog = true;
          }
        });
      }

      const payload = {
        apps: this.apps,
      };
      this.doCreateApp(payload)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Voila! Success create new app!`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.getAllEmployee();
        })
        .catch((error) => {
          return Swal.fire({
            icon: "error",
            title: `Oopss!`,
            text: `Error: ${error}`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.create_app_dialog = true;
            }
          });
        });
    },

    handleUpdateApp() {
      this.dialogVisible = false;
      if (this.apps == null || this.apps == "") {
        return Swal.fire({
          icon: "error",
          title: `Oopss!`,
          text: `Error: Apps name is required!`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.dialogVisible = true;
          }
        });
      }

      const payload = {
        apps: this.apps,
        id_apps: this.selected_apps.id_apps,
      };
      this.doUpdateApp(payload)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Voila! ${response}!`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.getAllEmployee();
        })
        .catch((error) => {
          return Swal.fire({
            icon: "error",
            title: `Oopss!`,
            text: `Error: ${error}`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.dialogVisible = true;
            }
          });
        });
    },

    handleDeleteApp(id, name) {
      Swal.fire({
        icon: "info",
        title: `Are you sure want to delete app: ${name}? This action can't be undone.`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            id_apps: id,
          };
          this.doDeleteApp(payload)
            .then((response) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: `Voila! ${response}!`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.getAllEmployee();
            })
            .catch((error) => {
              return Swal.fire({
                icon: "error",
                title: `Oopss!`,
                text: `Error: ${error}`,
              }).then((result) => {});
            });
        }
      });
    },

    handleOpenApp(data) {
      this.selected_user = data;
      const payload = {
        apps: "all",
        id_user: data.id_user,
      };
      this.fetchUserApps(payload)
        .then((response) => {
          response.forEach((data) => {
            this.apps_permission.push(data.id_apps);
          });
          this.old_apps_permission = this.apps_permission;
          this.user_apps_permission = response;
          this.add_apps_dialog = true;
        })
        .catch((error) => {
          this.old_apps_permission = [];
          this.user_apps_permission = [];
          this.add_apps_dialog = true;
        });
    },

    cancelOpenApp() {
      this.selected_user = null;
      this.apps_permission = [];
      this.old_apps_permission = [];
      this.add_apps_dialog = false;
    },

    handleAddAppPermission() {
      let not_yet_added = []; // Will inserted to user_apps
      let already_added = []; // Will skipped on inserting
      let delete_added = []; //Will delete on insert

      // Looking for apps that already assigned to user
      this.apps_permission.forEach((app) => {
        if (this.old_apps_permission.includes(app)) {
          already_added.push(app);
        } else {
          not_yet_added.push(app);
        }
      });

      // Looking for apps that will remove from user assigned
      this.old_apps_permission.forEach((app) => {
        if (!already_added.includes(app)) {
          delete_added.push(app);
        } else {
          already_added.slice(app);
        }
      });

      let user_apps_deleted = [];
      delete_added.forEach((app) => {
        this.user_apps_permission.filter((data) => {
          if (data.id_apps == app) {
            user_apps_deleted.push(data.id_user_apps);
          }
        });
      });

      // revoke access user to selected apps
      user_apps_deleted.forEach((id) => {
        const payload = id;
        this.doRevokeAppPermission(payload);
      });

      // add access to apps
      not_yet_added.forEach((app) => {
        const payload = {
          id_user: this.selected_user.id_user,
          id_apps: app,
          id_position: "1",
          is_active: "1",
        };
        this.doAddAppPermission(payload);
      });

      this.cancelOpenApp();

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Voila! Success renew user app permission!`,
        showConfirmButton: false,
        timer: 2000,
      });
      this.getAllEmployee();
    },

    handleOpenMenu(data) {
      this.selected_user = data;
      const payload = {
        apps: "all",
        id_user: data.id_user,
      };
      this.fetchUserApps(payload)
        .then((response) => {
          this.apps_permission = response;
          this.add_menu_dialog = true;
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: `Oopss!`,
            text: `Error: ${error}!`,
          });
        });
    },

    handleChangeApp() {
      console.log("h");
      this.is_loading_menu_data = true;
      this.list_app_menu = [];
      const payload_menu_apps = {
        apps: this.selected_user_app.id_apps,
      };
      this.fetchMenuByApps(payload_menu_apps).then((response) => {
        response.detail.forEach((menu) => {
          menu.sub_menu.forEach((submenu) => {
            const data = {
              id: submenu.id_sub_menu,
              menu: menu.menu + ">" + submenu.sub_menu,
            };
            this.list_app_menu.push(data);
          });
        });
      });

      const payload_permission = {
        id_user_apps: this.selected_user_app.id_user_apps,
      };

      this.fetchUserMenuPermission(payload_permission)
        .then((response) => {
          response.menu.forEach((menu) => {
            menu.sub_menu.forEach((submenu) => {
              const data = {
                id: submenu.id_sub_menu,
                // menu: menu.menu + ">" + submenu.sub_menu,
              };
              this.selected_menu_apps.push(data.id);
            });
          });
          this.is_loading_menu_data = false;
          // this.list_menu_permission = response
        })
        .catch((error) => {
          this.selected_menu_apps = [];
          this.is_loading_menu_data = false;
        });
    },

    handleCancelUpdateMenu() {
      this.selected_menu_apps = [];
      this.selected_user_app = null;
      this.list_app_menu = [];
      this.add_menu_dialog = false;
      this.apps_permission = [];
    },

    handleUpdateMenu() {
      const id_user_apps = this.selected_user_app.id_user_apps;
      this.doRevokePrevMenuPermission(id_user_apps)
        .then((response) => {
          this.add_menu_dialog = false;
          this.selected_menu_apps.forEach((submenu) => {
            const payload_menu_apps = {
              id_user_apps: this.selected_user_app.id_user_apps,
              id_sub_menu: submenu,
            };
            this.doAddMenuPermission(payload_menu_apps);
          });

          this.handleCancelUpdateMenu();

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Voila! Success renew user menu permission!`,
            showConfirmButton: false,
            timer: 2000,
          });
          this.getAllEmployee();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: `Oopss!`,
            text: `Error: ${error}`,
          });
        });
    },
  },

  computed: {
    userData() {
      return this.$store.state.administrator.user;
    },
    appsData() {
      return this.$store.state.administrator.apps;
    },
  },
};
</script>
