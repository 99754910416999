<template>
  <el-form :model="form" label-width="150px">
    <el-form-item label="Set Reminder">
      <el-col :span="11">
        <el-date-picker
          v-model="form.date1"
          type="datetime"
          placeholder="Pick a date"
          style="width: 100%"
          value-format="YYYY-MM-DD HH:mm:ss"
          :disabled-date="disabledDate"
        />
        <small>(Optional)</small>
      </el-col>
    </el-form-item>
    <el-form-item label="Company Name">
      <el-autocomplete
        class="inline-input"
        v-model="state1"
        :fetch-suggestions="querySearch"
        placeholder="Input Company Name"
        clearable
        @select="handleSelect"
        style="width: 100%"
      ></el-autocomplete>
    </el-form-item>
    <!-- <el-form-item label="" v-if="selectedCompany != null">
      <el-col>
        <span>
          Company Name:
          {{ selectedCompany.name }}
        </span>
        <br />
        <span>
          Company Address:
          {{ selectedCompany.address }}
        </span>
        <br />
        <span>
          Phone:
          {{ selectedCompany.phone }}
        </span>
      </el-col>
    </el-form-item> -->

    <el-form-item label="Project Name">
      <el-autocomplete
        class="inline-input"
        v-model="state2"
        :fetch-suggestions="querySearchProject"
        placeholder="Input Project Name"
        clearable
        :disabled="disable_project"
        @select="handleSelectProject"
        style="width: 100%"
      ></el-autocomplete>
    </el-form-item>
    <el-form-item label="" v-if="selectedProject !== null">
      <el-col class="p-0">
        <span class="d-flex justify-content-between">
          <el-badge
            :value="selectedProject.call_number"
            class="item"
            type="danger"
          >
            <el-button type="info">Call</el-button>
          </el-badge>
          <el-badge
            :value="selectedProject.email_number"
            class="item"
            type="danger"
          >
            <el-button type="info">Email</el-button>
          </el-badge>
          <el-badge
            :value="selectedProject.visit_number"
            class="item"
            type="danger"
          >
            <el-button type="info">Visit</el-button>
          </el-badge>
          <el-badge
            :value="selectedProject.online_meeting_number"
            class="item"
            type="danger"
          >
            <el-button type="info">Online Meeting</el-button>
          </el-badge>
        </span>
      </el-col>
    </el-form-item>
    <el-form-item label="Date">
      <el-date-picker
        v-model="form.date2"
        type="datetime"
        placeholder="Pick a date"
        style="width: 100%"
        format="YYYY-MM-DD HH:mm:ss"
      />
    </el-form-item>
    <el-form-item label="Notes">
      <el-input v-model="form.desc" type="textarea" />
    </el-form-item>
    <el-form-item>
      <el-radio-group
        v-for="stage in fetchStagingData"
        :key="stage.id"
        v-model="form.stage"
      >
        <el-radio :label="stage.id">{{ stage.name }}</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="Progress">
      <el-col class="p-0">
        <div class="d-flex justify-content-start align-items-center">
          <input
            style="width: 80%"
            type="range"
            placeholder="Input notes"
            @input="actionStep"
            min="0" 
            max="90" 
            step="30"
            v-model="progress_slide"
          />
          <span style="margin-left: 10px">{{ progress_slide }}%</span>
        </div>
        <span style="margin-left: 10px" v-if="selectedProject != null"
          >Last progress: {{ selectedProject.progress }}%</span
        >
      </el-col>
    </el-form-item>
    <el-form-item>
      <el-button type="success" @click="onSubmit" class="bg-gradient-success"
        >Create</el-button
      >
      <el-button @click="closeAdd" class="bg-gradient-danger text-light">
        <span class="text-light">Cancel</span>
      </el-button>
    </el-form-item>
  </el-form>

  <el-dialog
    v-model="add_pic"
    title="Tambah PIC"
    width="40%"
  >
    <span>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">PIC Name <span class="text-danger">*</span></label>
        <input type="text" class="form-control" v-model="formPic.name" placeholder="PIC Name">
      </div>
      <!-- <el-form-item label="PIC Name">
        <el-input v-model="formPic.name" type="text" />
      </el-form-item> -->
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">PIC Email <span class="text-danger">*</span></label>
        <input type="text" class="form-control" v-model="formPic.email" placeholder="PIC Email">
      </div>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">PIC Phone <span class="text-danger">*</span></label>
        <input type="number" class="form-control" v-model="formPic.phone" placeholder="PIC Phone">
        <input type="number" class="form-control" hidden v-model="formPic.employee_id" placeholder="PIC Phone">
      </div>
    </span>
    <select class="form-select" aria-label="Default select example" @change="getStatus($event)">
      <option selected disabled>Choose Status</option>
      <option value="1">Manager</option>
      <option value="2">Assistant Manager</option>
      <option value="3">Supervisor</option>
      <option value="4">Sales Admin</option>
      <option value="5">Account Manager</option>
      <option value="6">Outsource</option>
    </select>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="success"
          class="bg-gradient-success"
          @click="submitDataPic()"
        >
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!-- End -->
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { format } from "date-fns";
import Swal from "sweetalert2";

import { CircleCheckFilled, CircleClose } from "@element-plus/icons-vue";

import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  data() {
    return {
      form: {
        date1: "",
        date2: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        desc: "I just create an activity",
        stage: 1,
        state1: "",
        state2: "",
        progress_percent: 0,
        progress_star: 0,
      },
      disable_project: true,
      state1: "",
      state2: "",
      formPic: {
        name: "",
        status: null,
        phone: null,
        email: "",
        employee_id: this.$store.state.eraforce.user_data.employee_id,
        customer_id: null,
      },
      add_pic: false,
      selectSlider: [30, 60, 100],
      progress_slide: 0,
      companies: [],
      projects: [],
      selectedCompany: {},
      selectedProject: null,
      icons: [CircleCheckFilled, CircleCheckFilled, CircleCheckFilled],
    };
  },
  components: {
    ArgonInput,
    ArgonButton,
  },
  mounted() {
    this.companies = this.fetchEmployeeCompanyData;
    this.fetchStaging();
    this.activityName;
  },

  methods: {
    ...mapActions({
      fetchStaging: "eraforce/fetchStaging",
      fetchEmployeeProject: "eraforce/fetchEmployeeProject",
      doCallActivity: "eraforce/doCallActivity",
      fetchAllActivity: "eraforce/fetchAllActivity",
      doAddClientPIC: "eraforce/doAddClientPIC",
      fetchEmployeeCompany: "eraforce/fetchEmployeeCompany",
    }),
    ...mapMutations({
      SET_ACTIVITY_MODAL_NOT_ACTIVE: "eraforce/SET_ACTIVITY_MODAL_NOT_ACTIVE",
      SET_ACTIVITY_MODAL_ACTIVE: "eraforce/SET_ACTIVITY_MODAL_ACTIVE",
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
    }),

    disabledDate(date) {
      const now = new Date();
      const prev = now.setDate(now.getDate() - 1);
      return date.getTime() < prev;
    },

    actionStep() {
      if(this.progress_slide == 90) {
        this.progress_slide = 100;
      }
    },

    thisFetchDataEmployee() {
      this.fetchEmployeeCompany()
        .then((response) => {
          console.log('GET EMPLOYEE WORKS');
          this.companies = response;
          console.log(response);
        })
        .catch((err) => {
          console.log('FAILED');
        })
    },

    actionStep() {
      if(this.progress_slide == 90) {
        this.progress_slide = 100;
      }
    },

    submitDataPic() {
      const payload = {
        name: this.formPic.name,
        status: this.formPic.status,
        phone: this.formPic.phone,
        email: this.formPic.email,
        employee_id: this.formPic.employee_id,
        company_id: this.formPic.customer_id,
      }
      this.doAddClientPIC(payload)
        .then((response) => {
          alert('Created Success');
          console.log(response);
          this.add_pic = false;
          this.cleanFormPic();
          this.thisFetchDataEmployee();
        })
        .catch((err) => {
          alert('failed');
          console.log(err);
        })
    },

    cleanFormPic() {
      this.formPic.name = "";
      this.formPic.status = null;
      this.formPic.phone = null;
      this.formPic.email = "";
      this.formPic.customer_id =  null;
    },

    getStatus(event) {
      const status = event.target.value;
      this.formPic.status = status;
    },

    fetchEmployeeProjects() {
      let payload = {
        project_name: this.selectedCompany.name,
        employee_id: this.getUserLoginData.id,
        status_project: "active",
        page: 1,
        target_status: null,
      };
      this.fetchEmployeeProject(payload).then((response) => {
        this.projects = response;
        this.disable_project = false;
      });
    },

    closeAdd() {
      this.selectedProject = {};
      this.selectedCompany = {};
      this.SET_ACTIVITY_MODAL_NOT_ACTIVE();
    },

    querySearch(query, cb) {
      var companies = this.companies;
      var results = query
        ? companies.filter(this.createFilter(query))
        : companies;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.name}`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilter(queryString) {
      return (company) => {
        return (
          company.name.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    },

    handleSelect(item) {
      const length = item.detail.detail.length;

      this.formPic.customer_id = item.detail.id
      console.log(item.detail);

      if(length == 0) {
        this.add_pic = true;
      }

      this.disable_project = true;
      this.selectedCompany = item.detail;
      this.form.state1 = this.selectedCompany.id;
      this.fetchEmployeeProjects();
    },

    querySearchProject(query, cb) {
      var projects = this.projects;
      var results = query
        ? projects.filter(this.createFilterProject(query))
        : projects;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.project.job} - ${result.project.progress}%`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);
    },

    createFilterProject(queryString) {
      return (project) => {
        return (
          project.project.name
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) == 0
        );
      };
    },

    initForm() {
      console.log(``);
      this.state1 = "";
      this.state2 = "";
      this.form.state1 = "";
      this.form.desc = this.activityName;
      this.progress_slide = 0;
      this.form.state2 = "";
      this.form.date1 = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.form.stage = 1;
      this.form.date2 = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.disable_project = true;
      this.selectedProject = null;
    },

    handleSelectProject(item) {
      this.selectedProject = item.detail.project;
      this.form.state2 = this.selectedProject.number;
      this.form.stage = this.selectedProject.status;
      this.progress_slide = this.selectedProject.progress;
    },

    onSubmit() {
      this.SET_ACTIVITY_MODAL_NOT_ACTIVE();
      if (this.form.date2 == "" || this.form.date2 == null) {
        return Swal.fire({
          title: "Oopss! Activity date is required!",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.SET_ACTIVITY_MODAL_ACTIVE();
          }
        });
      }
      if (this.form.state1 == "" || this.form.state1 == null) {
        return Swal.fire({
          title: "Oopss! Company name is required!",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.SET_ACTIVITY_MODAL_ACTIVE();
          }
        });
      }
      if (this.form.state2 == "" || this.form.state2 == null) {
        return Swal.fire({
          title: "Oopss! Project name is required!",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.SET_ACTIVITY_MODAL_ACTIVE();
          }
        });
      }
      if (this.progress_slide == 0 || this.progress_slide == null) {
        return Swal.fire({
          title: `Oopss! Progress is required!`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.SET_ACTIVITY_MODAL_ACTIVE();
          }
        });
      }
      if (this.progress_slide <= this.selectedProject.progress) {
        return Swal.fire({
          title: `Oopss! Progress must higher than ${this.selectedProject.progress}%!`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.progress_slide = this.selectedProject.progress;
            this.SET_ACTIVITY_MODAL_ACTIVE();
          }
        });
      }
      const payload = {
        company_id: this.form.state1,
        note: this.form.desc,
        employee_id: this.getUserLoginData.id,
        progress: this.progress_slide,
        project_id: this.form.state2,
        reminder_date: this.form.date1,
        staging_id: this.form.stage,
        call_date: this.form.date2,
        activity_id: this.$store.state.eraforce.activity_id,
      };
      this.doCallActivity(payload)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Success create activity!",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {});
          this.getCallActivity();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops... Failed create activity!",
            text: `Error: ${error}`,
          });
        });
    },

    getCallActivity() {
      const call_payload = {
        activity_id: this.$store.state.eraforce.activity_id || 3, //Call
        employee_id: null,
        first_date: format(new Date(), "yyyy-MM-dd 00:00:00"),
        last_date: format(new Date(), "yyyy-MM-dd 23:59:59"),
      };
      this.SET_ACTIVITY_CARD_LOADING();
      this.fetchAllActivity(call_payload).then((response) => {
        this.SET_ACTIVITY_CARD_NOT_LOADING();
        this.initForm();
      });
    },
  },

  computed: {
    fetchEmployeeCompanyData() {
      let data = this.$store.state.eraforce.employee_company;
      return data;
    },
    // fetchEmployeeProjectData() {
    //   let data = this.$store.state.eraforce.employee_project;
    //   return data;
    // },
    fetchStagingData() {
      return this.$store.state.eraforce.staging;
    },
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },
    getUserData() {
      return this.$store.state.eraforce.user_data;
    },

    activityName() {
      let id_activity = this.$store.state.eraforce.activity_id;
      if (id_activity == 3) {
        this.form.desc = "I just Create Call Activity";
      } else if (id_activity == 4) {
        this.form.desc = "I just Create Visit Activity";
      } else if (id_activity == 5) {
        this.form.desc = "I just Create Email Activity";
      } else if (id_activity == 9) {
        this.form.desc = "I just Create Online Meeting Activity";
      } else {
        this.form.desc = "I just Create Activity";
      }
      return this.form.desc;
    },
  },

  watch: {},
};
</script>
