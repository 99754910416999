<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="''"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 mb-2 container-fluid">
      <breadcrumbs
        :currentDirectory="currentRouteName"
        :currentPage="currentSubRouteName"
        textWhite="text-white"
      />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="'ms-md-auto'"
        ></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center ms-2">
            <div
              @click="goToHome"
              class="px-0 nav-link font-weight-bold text-white on-hover-mouse-clicked"
            >
              <i class="fa fa-home" :class="'me-sm-2'"></i>
              <span lass="d-sm-inline d-none">Home</span>
            </div>
          </li>
          <li class="nav-item d-flex align-items-center ms-2" v-if="this.currentRouteName == 'Goo Project (Goo Pro)'">
            <a href="javascript:;" class="nav-link text-white p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-bell cursor-pointer"></i>
            </a>
            <ul class="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" style="cursor: pointer;" @click="handleShowTasks(JSON.parse(items.data)['extra']['card_id'])" v-for="items in notification"
                    :key="items">
                  <div class="d-flex py-1">
                    <!-- <div class="my-auto">
                      JSON.parse(items.data)['extra']['card_id']
                      <img src="../../assets/img/team-2.jpg" class="avatar avatar-sm  me-3 ">
                    </div> -->
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal mb-1">
                        <span class="font-weight-bold">{{ JSON.parse(items.data)['title'] }}</span> 
                      </h6>
                      <p class="text-xs text-secondary mb-0">
                        <span class="font-weight-bold">{{ JSON.parse(items.data)['body'] }}</span>  <br><br>
                        <i class="fa fa-clock me-1"></i>
                        {{ timeAgo(items.created_at) }} 
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
          <!-- <li
            class="px-3 nav-item dropdown d-flex align-items-center"
            :class="'pe-2'"
          >
            <a
              href="#"
              class="p-0 nav-link text-white"
              :class="[showMenu ? 'show' : '']"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
              Notification
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/team-2.jpg"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New message</span> from
                        Laur
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        13 minutes ago
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/small-logos/logo-spotify.svg"
                        class="avatar avatar-sm bg-gradient-dark me-3"
                        alt="logo spotify"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New album</span> by
                        Travis Scott
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        1 day
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div
                      class="my-auto avatar avatar-sm bg-gradient-secondary me-3"
                    >
                      <svg
                        width="12px"
                        height="12px"
                        viewBox="0 0 43 36"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <title>credit-card</title>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-2169.000000, -745.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(453.000000, 454.000000)">
                                <path
                                  class="color-background"
                                  d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z"
                                  opacity="0.593633743"
                                />
                                <path
                                  class="color-background"
                                  d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        Payment successfully completed
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        2 days
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li> -->
          <li class="nav-item d-flex align-items-center">
            <div
              @click="goToProfile"
              class="px-2 nav-link font-weight-bold text-white on-hover-mouse-clicked d-flex align-items-center"
            >
              <!-- <i class="fas fa-user" :class="'me-sm-2'"></i> -->
              <div class="">
                <img
                  :src="getUserData.photo_url"
                  alt=""
                  class="img-navbar"
                  @error="imageLoadError"
                />
              </div>
              <span lass="d-sm-inline d-none">
                Hi,
                {{
                  getUserLoginData.user.fullname
                    ? getUserLoginData.user.fullname
                    : ""
                }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Detail Tasks -->
    <el-dialog v-model="detail_tasks" title="Detail Tasks" width="60%">
      <div class="row">
        <div class="col-md-9">
          <p>Created By <strong>{{ (showTasks[0].user.length > 0) ? showTasks[0].user[0].fullname : '--' }}</strong></p>
          <div class="card card-primary card-outline shadow p-3">
            <div class="mb-5">
              <div class="h5"
                ><i class="fas fa-chalkboard"></i> {{ showTasks[0].title }}
              </div>
              <!-- <div class="row">
                <div class="col-md-8">
                  <i class="fas fa-chalkboard"></i>
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                </div>
              </div> -->
              <!-- <span class="h5">
                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
              </span> -->
              <br />
              <span>in List</span>

              <!-- Member Card -->
              <div class="m-3" v-if="showTasks[0].member_card.length > 0">
                <h6>Member Ticket</h6>
                <div class="d-flex">
                  <div class="avatar-group ms-2">
                    <a
                      href="#"
                      @click="detailMemberTicket(showTasks[0])"
                      class="avatar avatar-xs border-0 rounded-circle"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      v-for="thems in showTasks[0].member_card"
                      :key="thems.id"
                      style="pointer: cursor;"
                    >
                      <img
                        alt="Image placeholder"
                        src="../../assets/img/team-3.jpg"
                      />
                    </a>
                  </div>
                  <div>
                    Total Members: {{ showTasks[0].member_card.length }}
                  </div>
                </div>
              </div>
              <!-- End Member Card -->

              <!-- Due Date -->
              <div class="m-3">
                <div class="row">
                  <div class="col-md-6">
                    <h6>Start Date</h6>
                    <button type="button" class="btn btn-info btn-sm">
                      <i class="fas fa-clock"></i>
                      {{ getDate(showTasks[0].start_date) }}
                    </button>
                  </div>
                  <div class="col-md-6">
                    <h6>Due Date</h6>
                    <!-- <div class="form-check">
                      <input class="form-check-input" type="checkbox" :value="showTasks[0].status" v-if="showTasks[0].status == 0 || showTasks[0].status == '' || showTasks[0].status == null" @change="updateChecklist($event)" />
                      <input class="form-check-input" type="checkbox" :value="showTasks[0].status" v-if="showTasks[0].status == 1" checked @change="updateChecklist($event)" />
                      
                    </div> -->
                    <button v-if="showTasks[0].due_date" type="button" :class="getColorDueDate(showTasks[0].due_date, showTasks[0].status) + ' btn btn-sm'">
                        <i class="fas fa-clock"></i>
                        {{ getDateDue(showTasks[0].due_date) }}
                      </button>
                      <button v-else type="button" class="btn btn-sm btn-warning" @click="showDates()">
                        <i class="fas fa-clock"></i>
                        Update Due Date
                      </button>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="flexCheckChecked" disabled :value="showTasks[0].status" v-if="showTasks[0].status == 0 || showTasks[0].status == '' || showTasks[0].status == null" @change="updateChecklist($event)" />
                      <input class="form-check-input" type="checkbox" id="flexCheckChecked" disabled :value="showTasks[0].status" v-if="showTasks[0].status == 1" checked @change="updateChecklist($event)" />
                      <label class="form-check-label" for="flexCheckChecked">
                        Complete
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Description -->
            <div>
              <div class="mb-5">
                <span class="h5">
                  <i class="fas fa-th-list"></i> Description</span>
                <br />
                <div
                  class="m-4"
                  style="cursor: pointer"
                  v-if="!edit_description"
                >
                  {{ showTasks[0].description }}
                </div>

                <div class="m-4" v-else>
                  <textarea
                    class="form-control"
                    rows="2"
                    placeholder="Description"
                    v-model="showDescription"
                  ></textarea>
                  <div class="mt-3">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      @click="submitEditDescription(showTasks[0])"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm ms-1"
                      @click="handleRemoveEditDescription()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Description -->

            <!-- Attachment -->
            <div>
              <div class="mb-5">
                <span class="h5"
                  ><i class="fas fa-paperclip"></i> Attachment</span
                >
                <br />
                <div class="mt-2">
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                      v-for="attach in showTasks[0].attachments"
                      :key="attach.id_attach"
                    >
                      <div class="d-flex px-2 py-1">
                        <div>
                          <a :href="getUrl(attach)" target="_blank">
                            <img
                              :src="getAttachment(attach)"
                              class="avatar avatar-xl me-3 position-relative"
                              alt="user1"
                            />
                          </a>
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ attach.name }}</h6>
                          <p class="text-xs text-secondary mb-0">
                            {{ attach.created_at }}
                          </p>
                        </div>
                      </div>
                      <div class="ms-auto text-end">
                        <div class="justify-content-center">
                          <el-dropdown>
                            <span class="el-dropdown-link">
                              <i class="fas fa-caret-down"></i>
                            </span>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item
                                  @click="HandleEditAttachment(attach)"
                                >
                                  Edit
                                </el-dropdown-item>
                                <el-dropdown-item
                                  @click="HandleDeleteAttachment(attach)"
                                >
                                  Delete
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- End Attachment -->

            <!-- Activity  -->
            <div>
              <span class="h5"><i class="fas fa-user-clock"></i> Activity</span>
              <br />
              <div>
                <ul class="list-group">
                  <li
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                    v-for="cmt in showTasks[0].comments"
                    :key="cmt.id_comment"
                  >
                    <div class="d-flex px-2 py-1">
                      <div>
                        <img
                          :src="getImageComment(cmt.image)"
                          class="avatar avatar-sm me-3"
                          alt="user1"
                        />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ cmt.user }}</h6>
                        <p class="text-xs text-secondary mb-0">
                          {{ cmt.created_at }}
                        </p>

                        <div class="mt-2" v-if="cmt.type == 'MP3'">
                          <label>
                            <button class="btn btn-primary btn-sm" @click="handleSound(cmt.description)">
                              <span class="fa fa-play-circle-o fa-lg" v-if="!sound"></span>
                              <span class="fa fa-pause" v-else></span>
                            </button>
                          </label>
                        </div>
                        <div class="mt-2" v-else>
                          {{ cmt.description }}
                        </div>

                      </div>
                    </div>
                    <div class="ms-auto text-end">
                      <div>
                        <el-dropdown>
                          <span class="el-dropdown-link">
                            <i class="fas fa-caret-down"></i>
                          </span>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <el-dropdown-item
                                disabled
                                v-if="!cmt.type == 'MP3'"
                              >
                                Edit
                              </el-dropdown-item>
                              <el-dropdown-item
                              disabled
                              >
                                Delete
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label>Add To Card</label>
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            disabled
          >
            <i class="fas fa-users"></i>
            Members
          </button>
          <!-- <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            @click="showLabels()"
          >
            <i class="fas fa-tags"></i> Labels
          </button> -->
          <button type="button" class="btn btn-secondary w-100 btn-sm" disabled>
            <i class="fas fa-check-square"></i> Checklist
          </button>
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            disabled
          >
            <i class="fas fa-calendar-alt"></i> Dates
          </button>
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            disabled
          >
            <i class="fas fa-paperclip"></i> Attachment
          </button>

          <div class="mt-2">
            <label>Actions</label>
            <button type="button" class="btn btn-secondary w-100 btn-sm" disabled @click="handleMove()">
              <i class="fas fa-solid fa-arrow-right"></i> Move
            </button>
            <button type="button" class="btn btn-secondary w-100 btn-sm" disabled @click="handleCopy()">
              <i class="fas fa-calendar-alt"></i> Copy
            </button>
            <button type="button" class="btn btn-secondary w-100 btn-sm" disabled @click="handleProcess()">
              <i class="fas fa-calendar-alt"></i> Archive
            </button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- End Detail Tasks -->
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import axios from 'axios';

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      notification: [],
      detailMember: null,
      detail_card: false,
      detail_tasks: false,
      showTasks: {},
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  mounted() {
    this.fetchNotificationData()
  },
  methods: {
    ...mapMutations([
      "navbarMinimize",
      "toggleConfigurator",
      "SET_APP_PAGE_NAME",
    ]),
    ...mapActions(["toggleSidebarColor"], {
      fetchNotification: "gopro/fetchNotification",
      fetchCard: "gopro/fetchCard",
    }),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    fetchNotificationData() {
      axios.get("https://goopro.erakomp.co.id/api/notifications", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token"),
        }
      })
      .then((results) => {
        this.notification = results.data.data;
        console.log('SUCCESS');
      })
      .catch((error) => {
        console.log('ERROR');
        console.log(error);
      })
    },

    goToHome() {
      this.SET_APP_PAGE_NAME("");
      this.$router.push({ name: "Permisson" });
      const routeArr = this.$route.path.split("/");
      if (routeArr[1] == "2") {
        this.$store.dispatch("eraforce/removeEraforceData");
      }
    },

    timeAgo(date) {
      date = new Date(date);
      const seconds = Math.floor((new Date() - date) / 1000);
      let interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return interval + ' years ago';
      }

      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + ' months ago';
      }

      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + ' days ago';
      }

      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + ' hours ago';
      }

      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + ' minutes ago';
      }

      if(seconds < 10) return 'just now';

      return Math.floor(seconds) + ' seconds ago';
    },

    getDate(date) {
        const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let dates = new Date(date);
        let day = dates.getDate();
        let month = dates.getMonth();
        var year = dates.getFullYear();
        
        // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
        let now = new Date();
        let exam = "";
        if (dates > now) {
          exam = "on going";
        } else {
          exam = "over due";
        }

        let result = day + "/" + monthNames[month] + "/" + year ;
        return result;
      },

      getDateDue(date) {
        const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let dates = new Date(date);
        let day = dates.getDate();
        let month = dates.getMonth();
        var year = dates.getFullYear();
        const minute = 1000 * 60;
        const hour = minute * 60;
        const days = hour * 24;
        const years = days * 365;
        let time = dates.getHours() + ":" + dates.getMinutes();
        let hasil = Math.round(dates.getTime() / year);



        // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
        let now = new Date();
        let exam = "";
        if (dates > now) {
          exam = "on going";
        } else {
          exam = "over due";
        }

        let result = day + "/" + monthNames[month] + "/" + year + " : " + time;
        return result;
      },

      getColorDueDate(date, status) {
        let exam = "";
        if(date == null || date == '') {
          exam = "btn-danger"
          return exam;
        }
        let dates = new Date(date);
        let day = dates.getDay();
        let month = dates.getMonth();
        let now = new Date();
        if (dates > now && status == 0) {
          exam = "bg-gradient-info";
          return exam;
        } else if(dates == now && status == 0) {
          exam = "bg-gradient-warning";
          return exam;
        } else if(status == 1) {
          exam = "bg-gradient-success";
          return exam;
        } else {
          exam = "bg-gradient-danger"
          return exam;
        }

        
      },

      getImageComment(img) {
        // return `https://salesforce.klikpersada.co.id/ns/employee/${img}`;
        return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
      },

      getColor(date, status) {
        // let dates = new Date(date);
        // let day = dates.getDay();
        // let month = dates.getMonth();
        // let now = new Date();
        // let exam = "";
        // if(status == 1) {
        //   exam = "bg-gradient-success";
        //   return exam;
        // } else {
        //   exam = "bg-gradient-info"
        //   return exam;
        // }

        let exam = "";
        if(date == null || date == '') {
          exam = "btn-danger"
          return exam;
        }
        let dates = new Date(date);
        let day = dates.getDay();
        let month = dates.getMonth();
        let now = new Date();
        if (dates > now && status == 0) {
          exam = "bg-gradient-info";
          return exam;
        } else if(dates == now && status == 0) {
          exam = "bg-gradient-warning";
          return exam;
        } else if(dates == now) {
          exam = "bg-gradient-warning";
          return exam;
        } else if(status == 1) {
          exam = "bg-gradient-success";
          return exam;
        } else if(dates > now) {
          exam = "bg-gradient-info"
          return exam;
        } else {
          exam = "bg-gradient-danger"
          return exam;
        }
      },

      handleShowTasks(data) {
        let payload = {
          id_card: data,
          search: null,
          order: null,
        };

        const params = [
          `id_card=${data}`,
          `search=`,
          `order=`,
        ].join("&");
        axios.get(`https://goopro.erakomp.co.id/api/card?${params}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("token"),
          }
        })
        .then((response) => {
            ElNotification({
              title: "Viola!",
              message: "Success",
              type: "success",
            });
            this.showTasks = response.data.data;
            this.detail_tasks = true;
          })
          .catch((err) => {
            ElNotification({
              title: "Error!",
              message: "Tasks not found",
              type: "error",
            });
          });

        // this.fetchCard(payload)
          
      },

      getAttachment(img) {
        let gambar = "";
        if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG") {
          gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
        } else {
          gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
        }

        return gambar;
      },

      getUrl(img) {
        let gambar = "";
        if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG" || img.type == "PDF" || img.type == "DOC") {
          gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
        } else {
          gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
        }

        return gambar;
      },
      // End

    goToProfile() {
      return;
      this.SET_APP_PAGE_NAME("");
      this.$router.push({ name: "ProfileNonSales" });
    },

    imageLoadError() {
      return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
    },
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$store.state.dashboard_app_title;
    },
    currentSubRouteName() {
      return this.$store.state.dashboard_app_sub_title ?? "none";
    },
    getUserLoginData() {
      return this.$store.state.auth.login_data;
    },
    getUserData() {
      return this.$store.state.eraforce.user_data;
    },
  },
};
</script>

<style scoped>
.img-navbar {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #ededed;
  margin-right: 10px;
}
</style>
