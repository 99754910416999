<template>
  <!-- Hello World {{ getReporting }} -->
  <div class="mx-4">
    <div class="card p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <span class="text-bold"> Reporting </span>
        </div>
        <div>
          <argon-button
            variant="gradient"
            color="success"
            size="md"
            @click="handleExportReporting"
          >
            Export Excel
          </argon-button>
        </div>
      </div>
    </div>

    <div class="card p-3 mb-4">
      <div class="d-flex justify-content-evenly align-items-center">
        <div class="d-flex">
          <div class="form-group">
            <label>Project</label>
            <select class="form-control" @change="handleBoard($event)">
              <option value="">All Project</option>
              <option v-for="item in dataBoard" :value="item.id_board" :key="item">
                {{ item.nm_board }}
              </option>
            </select>
          </div>
          <div class="form-group ms-1">
            <label>List</label>
            <select class="form-control" @change="handleKanban($event)">
              <option value="">All List</option>
              <option v-for="item in kanban" :value="item.id_kanban" :key="item">
                {{ item.title }}
              </option>
            </select>
          </div>
          
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-evenly align-items-center">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" @change="filterDate($event)" id="flexRadioDefault1" value="start">
              <label class="form-check-label" for="flexRadioDefault1">
                Start Date
              </label>
            </div>
            <div class="form-check ms-2">
              <input class="form-check-input" type="radio" name="flexRadioDefault" @change="filterDate($event)" id="flexRadioDefault1" value="due">
              <label class="form-check-label" for="flexRadioDefault1">
                Due Date
              </label>
            </div>
          </div>
          <!-- <div class="text-center">Due Date</div> -->
          
          <el-date-picker
            type="daterange"
            v-model="params.due_date.date_range"
            range-separator="To"
            start-placeholder="Start date due"
            end-placeholder="End date due"
            size="medium"
            value-format="YYYY-MM-DD"
            v-if="due"
            @change="handleChangeStartDate"
          />

          <el-date-picker
            type="daterange"
            v-model="params.start_date.date_range"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            size="medium"
            value-format="YYYY-MM-DD"
            v-if="start"
            @change="handleChangeStartDate"
          />

        </div>
      </div>
    </div>

    <div class="card p-3 mb-3">
      <el-input
        v-model="params.search"
        class="w-100 m-2"
        size="large"
        placeholder="Search by Ticket/Tasks/Kanban/Board"
        :prefix-icon="Search"
        @change="getTicketData"
      />
    </div>

    <div class="card p-3" v-if="is_loading">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div class="card p-3" v-else>
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                No
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                List
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Tasks
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Board
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Member
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Start Date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Due Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in reporting" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <span class="text-xs text-secondary mb-0">{{ index + 1 }}</span>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <p class="text-xs text-secondary mb-0">
                  {{ item.kanban_title }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <strong>
                  <p class="text-xs text-primary mb-0" style="cursor: pointer;" @click="handleShowTasks(item.id_card)">{{ item.card_title }}</p>
                </strong>
              </td>
              <td class="align-middle text-center text-sm">
                <p class="text-xs text-secondary mb-0">{{ item.board_title }}</p>
              </td>
              <td class="align-middle text-center text-sm">
                <div class="avatar-group">
                  <a
                    href="javascript:;"
                    class="avatar avatar-xs border-0 rounded-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    v-for="items in item.collab"
                    :key="items"
                    @click="detailReporting(item.id_card)"
                  >
                    <img
                      alt="Image placeholder"
                      src="../../../assets/img/team-3.jpg"
                    />
                  </a>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <p class="text-xs text-secondary mb-0">
                  {{ item.card_start_date }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <p class="text-xs text-secondary mb-0">
                  {{ item.card_due_date }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Detail -->
    <el-dialog v-model="detail_card" title="Member" width="30%">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Fullname</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in detailMember" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.fullname }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>
    <!-- End Detail -->

    <!-- Detail Tasks -->
    <el-dialog v-model="detail_tasks" title="Detail Tasks" width="60%">
      <div class="row">
        <div class="col-md-9">
          <p>Created By <strong>{{ (showTasks[0].user.length > 0) ? showTasks[0].user[0].fullname : '--' }}</strong></p>
          <div class="card card-primary card-outline shadow p-3">
            <div class="mb-5">
              <div class="h5"
                ><i class="fas fa-chalkboard"></i> {{ showTasks[0].title }}
              </div>
              <!-- <div class="row">
                <div class="col-md-8">
                  <i class="fas fa-chalkboard"></i>
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                </div>
              </div> -->
              <!-- <span class="h5">
                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
              </span> -->
              <br />
              <span>in List</span>

              <!-- Member Card -->
              <div class="m-3" v-if="showTasks[0].member_card.length > 0">
                <h6>Member Ticket</h6>
                <div class="d-flex">
                  <div class="avatar-group ms-2">
                    <a
                      href="#"
                      @click="detailMemberTicket(showTasks[0])"
                      class="avatar avatar-xs border-0 rounded-circle"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      v-for="thems in showTasks[0].member_card"
                      :key="thems.id"
                      style="pointer: cursor;"
                    >
                      <img
                        alt="Image placeholder"
                        src="../../../assets/img/team-3.jpg"
                      />
                    </a>
                  </div>
                  <div>
                    Total Members: {{ showTasks[0].member_card.length }}
                  </div>
                </div>
              </div>
              <!-- End Member Card -->

              <!-- Due Date -->
              <div class="m-3">
                <div class="row">
                  <div class="col-md-6">
                    <h6>Start Date</h6>
                    <button type="button" class="btn btn-info btn-sm">
                      <i class="fas fa-clock"></i>
                      {{ getDate(showTasks[0].start_date) }}
                    </button>
                  </div>
                  <div class="col-md-6">
                    <h6>Due Date</h6>
                    <!-- <div class="form-check">
                      <input class="form-check-input" type="checkbox" :value="showTasks[0].status" v-if="showTasks[0].status == 0 || showTasks[0].status == '' || showTasks[0].status == null" @change="updateChecklist($event)" />
                      <input class="form-check-input" type="checkbox" :value="showTasks[0].status" v-if="showTasks[0].status == 1" checked @change="updateChecklist($event)" />
                      
                    </div> -->
                    <button v-if="showTasks[0].due_date" type="button" :class="getColorDueDate(showTasks[0].due_date, showTasks[0].status) + ' btn btn-sm'">
                        <i class="fas fa-clock"></i>
                        {{ getDateDue(showTasks[0].due_date) }}
                      </button>
                      <button v-else type="button" class="btn btn-sm btn-warning" @click="showDates()">
                        <i class="fas fa-clock"></i>
                        Update Due Date
                      </button>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="flexCheckChecked" disabled :value="showTasks[0].status" v-if="showTasks[0].status == 0 || showTasks[0].status == '' || showTasks[0].status == null" @change="updateChecklist($event)" />
                      <input class="form-check-input" type="checkbox" id="flexCheckChecked" disabled :value="showTasks[0].status" v-if="showTasks[0].status == 1" checked @change="updateChecklist($event)" />
                      <label class="form-check-label" for="flexCheckChecked">
                        Complete
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Description -->
            <div>
              <div class="mb-5">
                <span class="h5">
                  <i class="fas fa-th-list"></i> Description</span>
                <br />
                <div
                  class="m-4"
                  style="cursor: pointer"
                  v-if="!edit_description"
                >
                  {{ showTasks[0].description }}
                </div>

                <div class="m-4" v-else>
                  <textarea
                    class="form-control"
                    rows="2"
                    placeholder="Description"
                    v-model="showDescription"
                  ></textarea>
                  <div class="mt-3">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      @click="submitEditDescription(showTasks[0])"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm ms-1"
                      @click="handleRemoveEditDescription()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Description -->

            <!-- Attachment -->
            <div>
              <div class="mb-5">
                <span class="h5"
                  ><i class="fas fa-paperclip"></i> Attachment</span
                >
                <br />
                <div class="mt-2">
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                      v-for="attach in showTasks[0].attachments"
                      :key="attach.id_attach"
                    >
                      <div class="d-flex px-2 py-1">
                        <div>
                          <a :href="getUrl(attach)" target="_blank">
                            <img
                              :src="getAttachment(attach)"
                              class="avatar avatar-xl me-3 position-relative"
                              alt="user1"
                            />
                          </a>
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ attach.name }}</h6>
                          <p class="text-xs text-secondary mb-0">
                            {{ attach.created_at }}
                          </p>
                        </div>
                      </div>
                      <div class="ms-auto text-end">
                        <div class="justify-content-center">
                          <el-dropdown>
                            <span class="el-dropdown-link">
                              <i class="fas fa-caret-down"></i>
                            </span>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item
                                  @click="HandleEditAttachment(attach)"
                                >
                                  Edit
                                </el-dropdown-item>
                                <el-dropdown-item
                                  @click="HandleDeleteAttachment(attach)"
                                >
                                  Delete
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- End Attachment -->

            <!-- Activity  -->
            <div>
              <span class="h5"><i class="fas fa-user-clock"></i> Activity</span>
              <br />
              <div>
                <ul class="list-group">
                  <li
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                    v-for="cmt in showTasks[0].comments"
                    :key="cmt.id_comment"
                  >
                    <div class="d-flex px-2 py-1">
                      <div>
                        <img
                          :src="getImageComment(cmt.image)"
                          class="avatar avatar-sm me-3"
                          alt="user1"
                        />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ cmt.user }}</h6>
                        <p class="text-xs text-secondary mb-0">
                          {{ cmt.created_at }}
                        </p>

                        <div class="mt-2" v-if="cmt.type == 'MP3'">
                          <label>
                            <button class="btn btn-primary btn-sm" @click="handleSound(cmt.description)">
                              <span class="fa fa-play-circle-o fa-lg" v-if="!sound"></span>
                              <span class="fa fa-pause" v-else></span>
                            </button>
                          </label>
                        </div>
                        <div class="mt-2" v-else>
                          {{ cmt.description }}
                        </div>

                      </div>
                    </div>
                    <div class="ms-auto text-end">
                      <div>
                        <el-dropdown>
                          <span class="el-dropdown-link">
                            <i class="fas fa-caret-down"></i>
                          </span>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <el-dropdown-item
                                disabled
                                v-if="!cmt.type == 'MP3'"
                              >
                                Edit
                              </el-dropdown-item>
                              <el-dropdown-item
                              disabled
                              >
                                Delete
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label>Add To Card</label>
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            disabled
          >
            <i class="fas fa-users"></i>
            Members
          </button>
          <!-- <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            @click="showLabels()"
          >
            <i class="fas fa-tags"></i> Labels
          </button> -->
          <button type="button" class="btn btn-secondary w-100 btn-sm" disabled>
            <i class="fas fa-check-square"></i> Checklist
          </button>
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            disabled
          >
            <i class="fas fa-calendar-alt"></i> Dates
          </button>
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            disabled
          >
            <i class="fas fa-paperclip"></i> Attachment
          </button>

          <div class="mt-2">
            <label>Actions</label>
            <button type="button" class="btn btn-secondary w-100 btn-sm" disabled @click="handleMove()">
              <i class="fas fa-solid fa-arrow-right"></i> Move
            </button>
            <button type="button" class="btn btn-secondary w-100 btn-sm" disabled @click="handleCopy()">
              <i class="fas fa-calendar-alt"></i> Copy
            </button>
            <button type="button" class="btn btn-secondary w-100 btn-sm" disabled @click="handleProcess()">
              <i class="fas fa-calendar-alt"></i> Archive
            </button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- End -->
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions, useStore } from "vuex";

export default {
  name: "GoappsAdminReporting",
  components: {
    ArgonButton,
  },

  data() {
    return {
      reporting: [],
      showTasks: {},
      params: {
        id_card: "",
        search: "",
        order: "",
        id_board: "",
        id_kanban: "",
        start_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        due_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
      },
      is_loading: false,
      is_table_loading: false,
      board: [],
      start: true,
      due: false,
      id_board: "",
      kanban: [],
      dataBoard: [],
      paramsBoard: {
        id_board: "",
        search: "",
        order: "",
      },
      detailMember: null,
      detail_card: false,
      detail_tasks: false,
    };
  },

  // beforeMount() {
  //   this.fetchCardData();
  // },

  mounted() {
    this.fetchReportingData();
    this.fetchBoardData();
  },

  methods: {
    ...mapActions({
      fetchCard: "gopro/fetchCard",
      fetchReporting: "gopro/fetchReporting",
      doExportReport: "gopro/doExportReport",
      doDetailReporting: "gopro/doDetailReporting",

      fetchBoard: "gopro/fetchBoard",
      fetchKanban: "gopro/fetchKanban",
      getBoards: "gopro/getBoards",
    }),

    fetchCardData() {
      this.is_table_loading = true;
      const payload = {
        id_card: this.params.id_card,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchCard(payload).then((response) => {
        this.is_table_loading = false;
      });
    },

    getDate(date) {
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let dates = new Date(date);
      let day = dates.getDate();
      let month = dates.getMonth();
      var year = dates.getFullYear();
      
      // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
      let now = new Date();
      let exam = "";
      if (dates > now) {
        exam = "on going";
      } else {
        exam = "over due";
      }

      let result = day + "/" + monthNames[month] + "/" + year ;
      return result;
    },

    getDateDue(date) {
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let dates = new Date(date);
      let day = dates.getDate();
      let month = dates.getMonth();
      var year = dates.getFullYear();
      const minute = 1000 * 60;
      const hour = minute * 60;
      const days = hour * 24;
      const years = days * 365;
      let time = dates.getHours() + ":" + dates.getMinutes();
      let hasil = Math.round(dates.getTime() / year);



      // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
      let now = new Date();
      let exam = "";
      if (dates > now) {
        exam = "on going";
      } else {
        exam = "over due";
      }

      let result = day + "/" + monthNames[month] + "/" + year + " : " + time;
      return result;
    },

    getColorDueDate(date, status) {
      let exam = "";
      if(date == null || date == '') {
        exam = "btn-danger"
        return exam;
      }
      let dates = new Date(date);
      let day = dates.getDay();
      let month = dates.getMonth();
      let now = new Date();
      if (dates > now && status == 0) {
        exam = "bg-gradient-info";
        return exam;
      } else if(dates == now && status == 0) {
        exam = "bg-gradient-warning";
        return exam;
      } else if(status == 1) {
        exam = "bg-gradient-success";
        return exam;
      } else {
        exam = "bg-gradient-danger"
        return exam;
      }

      
    },

    getImageComment(img) {
      // return `https://salesforce.klikpersada.co.id/ns/employee/${img}`;
      return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
    },

    getColor(date, status) {
      // let dates = new Date(date);
      // let day = dates.getDay();
      // let month = dates.getMonth();
      // let now = new Date();
      // let exam = "";
      // if(status == 1) {
      //   exam = "bg-gradient-success";
      //   return exam;
      // } else {
      //   exam = "bg-gradient-info"
      //   return exam;
      // }

      let exam = "";
      if(date == null || date == '') {
        exam = "btn-danger"
        return exam;
      }
      let dates = new Date(date);
      let day = dates.getDay();
      let month = dates.getMonth();
      let now = new Date();
      if (dates > now && status == 0) {
        exam = "bg-gradient-info";
        return exam;
      } else if(dates == now && status == 0) {
        exam = "bg-gradient-warning";
        return exam;
      } else if(dates == now) {
        exam = "bg-gradient-warning";
        return exam;
      } else if(status == 1) {
        exam = "bg-gradient-success";
        return exam;
      } else if(dates > now) {
        exam = "bg-gradient-info"
        return exam;
      } else {
        exam = "bg-gradient-danger"
        return exam;
      }
    },

    handleShowTasks(data) {
      let payload = {
        id_card: data,
        search: null,
        order: null,
      };

      this.fetchCard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          console.log(response);
          this.showTasks = response;
          this.detail_tasks = true;
          this.fetchKanbanData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error!",
            message: "Error",
            type: "error",
          });
        });
    },

    getAttachment(img) {
      let gambar = "";
      if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG") {
        gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
      } else {
        gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
      }

      return gambar;
    },

    getUrl(img) {
      let gambar = "";
      if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG" || img.type == "PDF" || img.type == "DOC") {
        gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
      } else {
        gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
      }

      return gambar;
    },

    detailReporting(event) {
      const payload = {
        id_card: event
      };
      this.doDetailReporting(payload)
        .then((response) => {
          this.detailMember = response.data;
          this.detail_card = true;
          console.log(this.detailMember);
        })
        .catch((err) => {
          this.detailMember = null;
          console.log(err);
        })
    },

    handleBoard(event) {
      this.params.id_kanban = "";
      this.params.id_board = event.target.value;
      this.fetchReportingData();
      this.fetchKanbanData();
    },

    handleKanban(event) {
      this.params.id_kanban = event.target.value;
      this.fetchReportingData();
    },

    fetchBoardData() {
      const payload = {
        id_board: this.paramsBoard.id_board,
        search: this.paramsBoard.search,
        order: this.paramsBoard.order,
      };

      this.getBoards(payload)
        .then((response) => {
          console.log(response);
          this.dataBoard = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    },

    fetchKanbanData() {
      const payload = {
        id_kanban: this.params.id_kanban,
        board_id: this.params.id_board,
        search: this.params.search,
        order: this.params.order,
      };

      this.fetchKanban(payload)
        .then((response) => {
          this.kanban = response;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    filterDate(arg) {
      const value = arg.target.value;
      if(value == 'start') {
        this.start = true;
        this.due = false;
        this.params.due_date.date_range = ["", ""];
      } else {
        this.due = true;
        this.start = false;
        this.params.start_date.date_range = ["", ""];
      }
    },

    handleChangeStartDate() {
      if (this.params.start_date.date_range == null) {
        this.params.start_date.date_range = ["", ""];
      }
      
      if (this.params.due_date.date_range == null) {
        this.params.due_date.date_range = ["", ""];
      }
      
      this.params.start_date.start_date = this.params.start_date.date_range[0];
      this.params.start_date.end_date = this.params.start_date.date_range[1];
      
      this.params.due_date.start_date = this.params.due_date.date_range[0];
      this.params.due_date.end_date = this.params.due_date.date_range[1];

      let payload = {
        start_date: this.params.start_date,
        end_date: this.params.due_date,
      };

      // console.log(payload);
      this.fetchReportingData();
    },

    handleExportReporting() {
      const payload = {
        id_card: this.params.id_card,
        search: this.params.search,
        order: this.params.order,
        start_date_start: this.params.start_date.start_date,
        start_date_end: this.params.start_date.end_date,
        due_date_start: this.params.due_date.start_date,
        due_date_end: this.params.due_date.end_date,
        id_board: this.params.id_board,
        id_kanban: this.params.id_kanban,
      };
      this.doExportReport(payload)
        .then((response) => {
          window.open(response.results, "_blank");
          this.params.start_date.start_date = "";
          this.params.start_date.end_date = "";
          this.params.start_date.date_range = ["", ""];
        })
        .catch((err) => {
          alert(JSON.stringify(err.message));
          this.params.start_date.start_date = "";
          this.params.start_date.end_date = "";
          this.params.start_date.date_range = ["", ""];
        });
    },

    getTicketData() {
      console.log(this.params);
      this.fetchReportingData();
    },

    fetchReportingData() {
      this.is_loading = true;
      const payload = {
        id_card: this.params.id_card,
        search: this.params.search,
        order: this.params.order,
        start_date_start: this.params.start_date.start_date,
        start_date_end: this.params.start_date.end_date,
        due_date_start: this.params.due_date.start_date,
        due_date_end: this.params.due_date.end_date,
        id_board: this.params.id_board,
        id_kanban: this.params.id_kanban,
        // start_date: {
        //   start_date: this.params.start_date.start_date,
        //   end_date: this.params.start_date.end_date,
        // },
        // end_date: {
        //   start_date: this.params.due_date.start_date,
        //   end_date: this.params.due_date.end_date,
        // }
      };
      console.log(payload);
      this.fetchReporting(payload).then((response) => {
        this.is_loading = false;
        console.log(response);
        this.reporting = response;
      })
      .catch((error) => {
        this.is_loading = false;
        console.log(error);
        this.reporting = [];
      })
    },
  },

  computed: {
    // getCard() {
    //   return this.$store.state.gopro.card;
    // },
    getBoard() {
      return this.$store.state.gopro.board;
    },
    getReporting() {
      return this.$store.state.gopro.reporting;
    },
    getUserLoginData() {
      return this.$store.state.auth.login_data;
    },
  },
};
</script>
